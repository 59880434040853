import { render, staticRenderFns } from "./AcceptRules.vue?vue&type=template&id=0c97cdb6&scoped=true&lang=pug&"
import script from "./AcceptRules.vue?vue&type=script&lang=js&"
export * from "./AcceptRules.vue?vue&type=script&lang=js&"
import style0 from "./AcceptRules.vue?vue&type=style&index=0&id=0c97cdb6&prod&scoped=true&lang=stylus&"
import style1 from "./AcceptRules.vue?vue&type=style&index=1&id=0c97cdb6&prod&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c97cdb6",
  null
  
)

export default component.exports