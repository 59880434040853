export default {
    tableCode: 'securitymodules',
    tableName: 'Модули безопасности',
    indexField: [
        'id_security_module'
    ],
    defaultOrder: {
        ['id_security_module']: 'ASC'
    },
    tableDescription: [
        {
            key: 'id_security_module',
            name: 'Идентификатор SAM/МКЭБ',
            type: BigInt,
        },
        {
            key: 'id_carrier',
            name: 'Идентификатор перевозчика',
            type: BigInt,
            relation: {
                table: 'carriersdata',
                view: 'carrier_name',
                join_rules: {
                    id_carrier: 'id',
                },
            }
        },
        {
            key: 'note',
            name: 'Примечание',
            type: String,
        },
        {
            key: 'add_date_time',
            name: 'Дата установки',
            type: Date,
        },
        {
            key: 'active',
            name: 'Статус активности',
            type: Boolean
        }
    ]
}
