export default {
    tableName: 'Станции Метрополитена',
    tableCode: 'metrostations',
    indexField: ['station_id'],
    viewField: 'description',
    defaultOrder: {
        station_id: 'ASC',
    },
    tableDescription: [
        {
            type: BigInt,
            name: 'Идентификатор',
            key: 'station_id',
            width: 150,
        },
        {
            type: String,
            name: 'Наименование',
            key: 'description',
        },
    ]
}
