import VueRouter from "vue-router";
import CardsData from "@/pages/NSI/CardsData";
import CarriersData from "@/pages/NSI/CarriersData";
import NSIHistory from "@/pages/NSI/History";
import MetroStations from "@/pages/NSI/MetroStations";
import RoutesData from "@/pages/NSI/RoutesData";
import RouteTypes from "@/pages/NSI/RouteTypes";
import Tarrifs from "@/pages/NSI/Tariffs";
import BoTypes from "@/pages/NSI/BoTypes";
import TransportCustomer from "@/pages/NSI/TransportCustomer";
import TransportTypes from "@/pages/NSI/TransportTypes";
import TransportData from "@/pages/NSI/TransportData";
import CarrierOffices from "@/pages/NSI/CarrierOffices";
import ZoneTariffs from "@/pages/NSI/ZoneTariffs";
import ZoneTariffsDetails from "@/pages/NSI/ZoneTariffsDetails";
import Blacklist from "@/pages/Blacklist/Blacklist";
import ReasonsData from "@/pages/Blacklist/ReasonsData";
import BlacklistDatBuffer from "@/pages/Blacklist/BlacklistDatBuffer";
import Request_History from "@/pages/NSI/Request_History";
import SourceSystems from "@/pages/NSI/SourceSystems";
import RequestTypes from "@/pages/NSI/RequestTypes";
import NSILogin from "@/pages/NSILogin";
import Store from "@/store/store"
import {ALLOWED_FOR_USER} from "@/constants";
import axios from "@/utils/axios";
import Users from "@/pages/Users";
import CardsCategories from "@/pages/PBRules/CardsCategories";
import AcceptRules from "@/pages/PBRules/AcceptRules";
import TariffseTicket from "@/pages/PBRules/TariffseTicket";
import TariffTicketTransfer from "@/pages/PBRules/TarrifseTicketTransfer";
import AcceptRulesDict from "@/pages/PBRules/AcceptRulesDict";
import Reports from "@/pages/Reports";
import ExtSales from "@/pages/NSI/ExtSales";
import ProtocolBocotExt from "@/pages/NSI/ProtocolBocotExt";
import Signatory from "@/pages/NSI/Signatory";
import JobTitle from "@/pages/NSI/JobTitle";
import BlackListFLC_Reg from "@/pages/Blacklist/BlackListFLC_Reg";
import BlackListFLC_Ext from "@/pages/Blacklist/BlackListFLC_Ext";
import Tariffs_bankcards from "@/pages/NSI/TariffsBankcards";
import Securitymodules from "@/pages/NSI/securitymodules";

export const ROUTE_CONSTANTS = {
    NSI_BLACKLIST: '/nsi-blacklist',
    NSI_BLACKLIST_REG: '/nsi-blacklist-reg',
    NSI_BLACKLIST_EXT: '/nsi-blacklist-ext',
    NSI_MANAGEMENT: '/nsi-management',

    NSI_BLACKLIST_DAT_BUFFER: '/nsi-blacklist/dat',
    NSI_BLACKLIST_LIST: '/nsi-blacklist/list',
    NSI_BLACKLIST_REASONS: '/nsi-blacklist/reasons',

    PB_CARDCATEGORIES: '/pb/cardcategories',
    PB_ACCEPT_RULES: '/pb/acceptrules',
    PB_ACCEPT_RULES_DICT: '/pb/acceptrulesdict',
    PB_TARIFFSE_TICKET: '/pb/tariff-se-ticket',
    PB_TARIFFSE_TICKET_TRANSFER: '/pb/tariff-se-ticket-transfer',


    NSI_CARDSDATA: '/nsi/cardsdata',
    NSI_CARRIERSDATA: '/nsi/carriersdata',
    NSI_CARRIERSOFFICES: '/nsi/carrieroffices',
    NSI_HISTORY: '/nsi/history',
    NSI_TARIFFS: '/nsi/tariffs',
    NSI_TARIFFS_BANKCARDS: '/nsi/tariffs_bankcards',
    NSI_ROUTESDATA: '/nsi/routesdata',
    NSI_SECURITY_MODULES: '/nsi/securitymodules',
    NSI_TRANSPORT_CUSTOMER: '/nsi/transportcustomer',
    NSI_TRANSPORT_TYPES: '/nsi/transporttypes',
    NSI_TRANSPORT_DATA: '/nsi/transportdata',
    NSI_BOTYPES: '/nsi/botypes',
    NSI_ROUTESTYPES: '/nsi/routestypes',
    NSI_METROSTATIONS: '/nsi/metrostations',
    NSI_ZONE_TARIFFS: '/nsi/zonetariffs',
    NSI_ZONE_TARIFFS_DETAILS: '/nsi/zonetariffsdetails',
    NSI_EXT_SALES: '/nsi/extsales',
    NSI_REQUEST_HISTORY: '/nsi/request-history',
    NSI_SOURCE_SYSTEMS: '/nsi/source-systems',
    NSI_REQUEST_TYPES: '/nsi/request-types',
    COT_REPORTS_MANAGEMENT: '/cot-reports-management',
    ADMINISTRATION: '/administration',
    ADMINISTRATION_USERS: '/administration/users',
    PROTOCOL_BOCOT_EXT: '/nsi/protocol-bocot-ext',
    JOB_TITLE: '/nsi/job-title',
    SIGNATORY: '/nsi/signatory',
    EXIT: '/exit',
    LOGIN: '/login',
}

const routes = [
    { path: ROUTE_CONSTANTS.NSI_BLACKLIST_LIST, component: Blacklist },
    { path: ROUTE_CONSTANTS.NSI_BLACKLIST_REG, component: BlackListFLC_Reg },
    { path: ROUTE_CONSTANTS.NSI_BLACKLIST_EXT, component: BlackListFLC_Ext },
    { path: ROUTE_CONSTANTS.JOB_TITLE, component: JobTitle },
    { path: ROUTE_CONSTANTS.SIGNATORY, component: Signatory },
    { path: ROUTE_CONSTANTS.NSI_SECURITY_MODULES, component: Securitymodules },
    { path: ROUTE_CONSTANTS.PROTOCOL_BOCOT_EXT, component: ProtocolBocotExt },
    { path: ROUTE_CONSTANTS.NSI_BLACKLIST_DAT_BUFFER, component: BlacklistDatBuffer },
    { path: ROUTE_CONSTANTS.NSI_BLACKLIST_REASONS, component: ReasonsData },
    { path: ROUTE_CONSTANTS.NSI_CARDSDATA, component: CardsData },
    { path: ROUTE_CONSTANTS.NSI_TARIFFS_BANKCARDS, component: Tariffs_bankcards },
    { path: ROUTE_CONSTANTS.NSI_CARRIERSDATA, component: CarriersData },
    { path: ROUTE_CONSTANTS.COT_REPORTS_MANAGEMENT, component: Reports, },
    { path: ROUTE_CONSTANTS.NSI_HISTORY, component: NSIHistory },
    { path: ROUTE_CONSTANTS.NSI_METROSTATIONS, component: MetroStations },
    { path: ROUTE_CONSTANTS.NSI_ROUTESDATA, component: RoutesData },
    { path: ROUTE_CONSTANTS.NSI_ROUTESTYPES, component: RouteTypes },
    { path: ROUTE_CONSTANTS.NSI_EXT_SALES, component: ExtSales },
    { path: ROUTE_CONSTANTS.NSI_ROUTESTYPES, component: RouteTypes },
    { path: ROUTE_CONSTANTS.NSI_BOTYPES, component: BoTypes },
    { path: ROUTE_CONSTANTS.NSI_TARIFFS, component: Tarrifs },
    { path: ROUTE_CONSTANTS.NSI_TRANSPORT_CUSTOMER, component: TransportCustomer },
    { path: ROUTE_CONSTANTS.NSI_TRANSPORT_TYPES, component: TransportTypes },
    { path: ROUTE_CONSTANTS.NSI_TRANSPORT_DATA, component: TransportData },
    { path: ROUTE_CONSTANTS.NSI_CARRIERSOFFICES, component: CarrierOffices },
    { path: ROUTE_CONSTANTS.NSI_ZONE_TARIFFS, component: ZoneTariffs },
    { path: ROUTE_CONSTANTS.NSI_ZONE_TARIFFS_DETAILS, component: ZoneTariffsDetails },
    { path: ROUTE_CONSTANTS.NSI_REQUEST_HISTORY, component: Request_History },
    { path: ROUTE_CONSTANTS.NSI_SOURCE_SYSTEMS, component: SourceSystems },
    { path: ROUTE_CONSTANTS.NSI_REQUEST_TYPES, component: RequestTypes },
    { path: ROUTE_CONSTANTS.LOGIN, component: NSILogin },
    { path: ROUTE_CONSTANTS.ADMINISTRATION_USERS, component: Users },
    { path: ROUTE_CONSTANTS.PB_CARDCATEGORIES, component: CardsCategories },
    { path: ROUTE_CONSTANTS.PB_ACCEPT_RULES, component: AcceptRules },
    { path: ROUTE_CONSTANTS.PB_TARIFFSE_TICKET, component: TariffseTicket },
    { path: ROUTE_CONSTANTS.PB_TARIFFSE_TICKET_TRANSFER, component: TariffTicketTransfer },
    { path: ROUTE_CONSTANTS.PB_ACCEPT_RULES_DICT, component: AcceptRulesDict },
    { path: '/', redirect: ROUTE_CONSTANTS.NSI_BLACKLIST_LIST },
    { path: '*', redirect: ROUTE_CONSTANTS.NSI_BLACKLIST_LIST },
]

const router = new VueRouter({
    mode: 'history',
    routes,
});

router.beforeEach(async (to, from, next) => {
    document.title = 'АРМ Оператора';
    try {
        const store = Store.get();

        window.router = router;

        if (!store.getters.role) {
            const authorization = localStorage.getItem('authorization');

            if (!authorization) {
                return;
            }
            axios.defaults.headers.common.Authorization = authorization;

            try {
                await store.dispatch('ok');
            } catch (e) {
                //
            }
        }

        const role = store.getters.role;

        const allowedRoutes = ALLOWED_FOR_USER[role];

        if (!allowedRoutes) {
            await next();
            return;
        }

        const currentPath = to.path === '/check-allowed' ? from.path : to.path;

        if (allowedRoutes.includes(currentPath)) {
            if (to.path === '/check-allowed') {
                await router.push(currentPath);
            } else {
                await next();
            }
        } else {
            await router.push(allowedRoutes[0]);
        }

    } catch (e) {
        if (e.name !== 'AxiosError') {
            console.error(e);
        }
    }
});

export default router;
