<template lang="pug">

  el-header(style="text-align: right; font-size: 12px; position: relative")
    el-button.menu_button(v-on:click="toggleMenu" :icon="icon")
    slot

</template>

<script>
import ArmIcon from "@/components/ArmIcon";
import { mapMutations, mapState } from "vuex";

export default {
  name: "ArmHeader",
  components: {
    ArmIcon,
  },
  computed: {
    ...mapState(['menuHidden']),
    icon() {
      return this.menuHidden ? 'el-icon-s-unfold' : 'el-icon-s-fold';
    }
  },
  methods: {
    ...mapMutations([ 'toggleMenu' ]),
  }
}
</script>

<style scoped lang="stylus">
.el-header
  background-color #9DC5D3
  color #333
  line-height 60px

.menu_button
  position absolute
  left 10px
  top 10px
  font-size 20px
  padding 9px 20px
</style>
