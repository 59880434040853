import {sort} from "@/constants";

export default {
    tableName: 'Зональная тарифная политика',
    tableCode: 'zonetariffsdetails',
    indexField: ['id_zonetariff', 'card_type', 'zone_begin', 'zone_end'],
    defaultOrder: [
        {
            key: 'id_zonetariff',
            type: sort.ASC,
        },
        {
            key: 'card_type',
            type: sort.ASC,
        },
        {
            key: 'zone_begin',
            type: sort.ASC,
        },
        {
            key: 'zone_end',
            type: sort.ASC,
        },
    ],
    tableDescription: [
        {
            type: BigInt,
            name: 'Код тарифа',
            key: 'id_zonetariff',
        },
        {
            type: String,
            name: 'Тип билета',
            key: 'card_type',
            relation: {
                table: 'cardsdataunique',
                join_rules: {
                    card_type: 'card_type',
                },
                view: 'card_description',
            }
        },
        {
            type: BigInt,
            key: 'tariff',
            name: 'Тариф',
        },
        {
            type: BigInt,
            key: 'zone_begin',
            name: 'Зона начала поездки',
        },
        {
            type: BigInt,
            key: 'zone_end',
            name: 'Зона окончания поездки',
        },
        {
            key: 'comment',
            name: 'Примечание',
            type: String,
        },
    ]
};
