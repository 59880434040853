export const USER_ROLES = {
    administrator: 'administrator',
    blacklist_operator: 'blacklist_operator',
    nsi_operator: 'nsi_operator',
    reports_operator: 'reports_operator',
    pb_rules_operator: 'pb_rules_operator',
};

export const ALLOWED_FOR_USER = {
    [USER_ROLES.nsi_operator]: [
        '/nsi/cardsdata',
        '/nsi/carriersdata',
        '/nsi/protocol-bocot-ext',
        '/nsi/carrieroffices',
        '/nsi/extsales',
        '/nsi/history',
        '/nsi/tariffs',
        '/nsi/tariffs_bankcards',
        '/nsi/routesdata',
        '/nsi/transportcustomer',
        '/nsi/transporttypes',
        '/nsi/transportdata',
        '/nsi/botypes',
        '/nsi/routestypes',
        '/nsi/metrostations',
        '/nsi/zonetariffs',
        '/nsi/zonetariffsdetails',
        '/nsi/request-history',
        '/nsi/source-systems',
        '/nsi/request-types',
        '/nsi/signatory',
        '/nsi/job-title',
        '/nsi/securitymodules',
    ],
    [USER_ROLES.blacklist_operator]: [
        '/nsi-blacklist/dat',
        '/nsi-blacklist/list',
        '/nsi-blacklist/reasons',
        '/nsi-blacklist-reg',
        '/nsi-blacklist-ext',
    ],
    [USER_ROLES.reports_operator]: [
        '/cot-reports-management',
    ],
    [USER_ROLES.pb_rules_operator]: [
        '/pb/cardcategories',
        '/pb/acceptrules',
        '/pb/acceptrulesdict',
        '/pb/tariff-se-ticket',
        '/pb/tariff-se-ticket-transfer',
    ],
    [USER_ROLES.administrator]: [
        '/nsi-blacklist-reg',
        '/nsi-blacklist-ext',
        '/nsi/tariffs_bankcards',
        '/nsi/protocol-bocot-ext',
        '/pb/cardcategories',
        '/pb/acceptrules',
        '/nsi/securitymodules',
        '/pb/acceptrulesdict',
        '/pb/tariff-se-ticket',
        '/pb/tariff-se-ticket-transfer',
        '/nsi-blacklist/list',
        '/cot-reports-management',
        '/nsi-blacklist/dat',
        '/nsi-blacklist/reasons',
        '/nsi/cardsdata',
        '/nsi/carriersdata',
        '/nsi/carrieroffices',
        '/nsi/history',
        '/nsi/tariffs',
        '/nsi/extsales',
        '/nsi/routesdata',
        '/nsi/transportcustomer',
        '/nsi/transporttypes',
        '/nsi/transportdata',
        '/nsi/botypes',
        '/nsi/routestypes',
        '/nsi/metrostations',
        '/nsi/zonetariffs',
        '/nsi/zonetariffsdetails',
        '/nsi/request-history',
        '/nsi/source-systems',
        '/nsi/request-types',
        '/administration/users',
        '/nsi/signatory',
        '/nsi/job-title',
    ]
}

export const sort = {
    DESC: 'DESC',
    ASC: 'ASC',
}
