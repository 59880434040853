export default {
    tableName: 'Заказчики перевозок',
    tableCode: 'transportcustomers',
    indexField: ['id_customer'],
    viewField: 'name',
    defaultOrder: {
        id_customer: 'ASC',
    },
    tableDescription: [
        {
            type: BigInt,
            name: 'Идентификатор',
            key: 'id_customer',
            width: 150,
        },
        {
            type: String,
            name: 'Наименование',
            key: 'name',
        },
        {
            type: String,
            key: 'contact',
            name: 'Контактная информация',
        },
    ]
}
