import {sort} from "@/constants";

export default {
    tableName: 'Филиалы перевозчиков',
    tableCode: 'carrieroffices',
    indexField: ['id_carrier', 'id_carrier_office'],
    viewField: 'name',
    defaultOrder: [
        {
            key: 'id_carrier',
            type: sort.ASC,
        },
        {
            key: 'id_carrier_office',
            type: sort.ASC,
        },
    ],
    tableDescription: [
        {
            type: BigInt,
            name: 'Идентификатор',
            width: 150,
            key: 'id_carrier_office',
        },
        {
            type: BigInt,
            name: 'Перевозчик',
            key: 'id_carrier',
            relation: {
                table: 'carriersdata',
                view: 'carrier_name',
                join_rules: {
                    id_carrier: 'id',
                },
            }
        },
        {
            type: String,
            name: 'Наименование',
            key: 'name',
        },
        {
            type: BigInt,
            name: 'Тип транспорта',
            key: 'transport_type',
            relation: {
                table: 'transporttypes',
                view: 'description',
                join_rules: {
                    transport_type: 'id',
                },
            },
        },
    ]
}
