import { render, staticRenderFns } from "./CarriersData.vue?vue&type=template&id=8892b2ea&scoped=true&lang=pug&"
import script from "./CarriersData.vue?vue&type=script&lang=js&"
export * from "./CarriersData.vue?vue&type=script&lang=js&"
import style0 from "./CarriersData.vue?vue&type=style&index=0&id=8892b2ea&prod&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8892b2ea",
  null
  
)

export default component.exports