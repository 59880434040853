<template lang="pug">

  iframe(src="/module/reports")

</template>

<script>

export default {
  name: 'Reports'
}

</script>
<style scoped lang="stylus">
iframe
  border none
  width 100%
</style>
