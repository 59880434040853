import {sort} from "@/constants";

export default {
    tableName: 'Справочник кодов платежных систем банковских карт',
    tableCode: 'paymentsystems',
    indexField: [
        'id',
    ],
    viewField: 'description',
    defaultSort: {
        id: sort.ASC,
    },
    tableDescription: [
        {
            type: BigInt,
            key: 'id',
            name: 'ID',
        },
        {
            type: String,
            name: 'Описание',
            key: 'description',
        }
    ]
}
