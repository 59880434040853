import {sort} from "@/constants";

export default {
    tableName: 'Параметры протокола БО ЦОТ',
    tableCode: 'protocol_bocot_ext',
    indexField: ['name'],
    defaultOrder: {
        name: sort.ASC,
    },
    tableDescription: [
        {
            key: 'name',
            name: 'Параметр',
            type: String,
        },
        {
            key: 'value',
            name: 'Значение',
            type: String,
        },
        {
            key: 'note',
            name: 'Примечание',
            type: String,
        }
    ]
}
