export default {
    tableName: 'Справочник тарифов',
    tableCode: 'tariffs',
    indexField: ['id_tariff'],
    defaultOrder: {
        id_tariff: 'ASC',
    },
    tableDescription: [
        {
            type: BigInt,
            name: 'Идентификатор',
            key: 'id_tariff',
        },
        {
            type: String,
            name: 'Тип билета',
            key: 'card_type',
            relation: {
                table: 'cardsdataunique',
                view: 'card_description',
                join_rules: {
                    card_type: 'card_type',
                },
            },
        },
        {
            type: BigInt,
            name: 'Сумма',
            key: 'tariff',
        },
        {
            type: Date,
            name: 'Начало действия',
            key: 'begin_date_time',
        },
        {
            type: Date,
            name: 'Окончание действия',
            key: 'end_date_time',
        },
        {
            type: BigInt,
            name: 'Тип маршрута',
            key: 'route_type',
            relation: {
                table: 'routetypes',
                view: 'description',
                join_rules: {
                    route_type: 'route_type',
                },
            },
        },
        {
            type: String,
            name: 'Примечание',
            key: 'comment',
        }
    ]
}
