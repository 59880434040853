export default {
  tableName: 'Справочник уникальных типов билетов',
  tableCode: 'cardsdataunique',
  indexField: ['card_type'],
  viewField: 'card_description',
  tableDescription: [
    {
      type: BigInt,
      name: 'Тип билета',
      key: 'card_type',
    },
    {
      type: BigInt,
      name: 'Вид билета',
      key: 'card_form',
    },
    {
      type: String,
      name: 'Наименование',
      key: 'card_description',
    }
  ]
};
