import {sort} from "@/constants";

export default {
    tableCode: 'signatory',
    tableName: 'Уполномоченные лица',
    indexField: ['id'],
    defaultOrder: {
        id: sort.ASC,
    },
    tableDescription: [
        {
            key: 'id',
            name: 'ID',
            type: BigInt,
        },
        {
            key: 'surname',
            name: 'Фамилия',
            type: String,
        },
        {
            key: 'name',
            name: 'Имя',
            type: String,
        },
        {
            key: 'middle_name',
            name: 'Отчество',
            type: String,
        },
        {
            key: 'id_job_title',
            type: BigInt,
            name: 'Должность',
            relation: {
                table: 'job_title',
                join_rules: {
                    id_job_title: 'id',
                },
                view: 'description'
            }
        },
    ]
}
