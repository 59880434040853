<template lang="pug">
  universal-table(
    :table-code="tableCode"
    :table-name="tableName"
    :index-field="indexField"
    :table-description="tableDescription"
    :default-order="defaultOrder"
  )
</template>

<script>
import ArmHeader from "@/components/ArmHeader";
import UniversalTable from "@/pages/UniversalTable";
import tables from "@/tables";

export default {
  name: "TariffSeTicket",
  components: {
    UniversalTable,
    ArmHeader,
  },
  data: tables['tariffse-tickettransfer'],
}
</script>

<style scoped lang="stylus">
.column
  flex-direction column

</style>
