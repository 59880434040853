<template lang="pug">
  el-row.filter__container
    template(v-for="item in structure" v-if="!item.filter_hidden")
      template(v-if="item.relation")
        el-select.input__column(
          :value="selectValue(item.relation.table, item.key, item)"
          v-on:input="val => insertSelect(val, item.key, item)"
          filterable
          :placeholder="item.name"
          clearable
        )
          el-option(
            v-for="item in selectOptions(item.relation.table, item.relation.filter_by, item.relation.depends_on)"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          )
      template(v-else-if="item.type === BigInt")
        el-input.input__column(
          type="string"
          :placeholder="item.name"
          v-on:input="val => insertBigNumber(val, item.key)"
          :value="localData[item.key]"
        )

      template(v-else-if="item.type === Number")
        el-input.input__column(
          type="number"
          :placeholder="item.name"
          v-model="localData[item.key]"
        )

      template(v-else-if="item.type === Date")
        el-date-picker.input__column(
          v-model="localData[item.key]"
          type="datetime"
          :placeholder="item.name")

      template(v-else-if="item.type === String")
        el-input.input__column(
          :placeholder="item.name"
          :value="stringValue(item.key)"
          v-on:input="val => insertString(val, item.key)"
        )
    el-button.filter__button(type="primary" v-on:click="search") Искать
    el-button.filter__button.reset__button(type="danger" circle v-on:click="reset" icon="el-icon-close")
    el-button.filter__button.reset__button(type="success" plain circle v-on:click="refresh" icon="el-icon-refresh-right")
    //el-button: el-icon(type="el-icon-close")

</template>

<script>
import tables from "@/tables";
import * as R from "ramda";
import { mapGetters } from "vuex";

export default {
  name: 'UniTableFilters',
  data: () => ({
    BigInt,
    localData: {},
  }),
  props: {
    structure: {
      type: Array,
    },
    targetData: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters({
      getTable: 'unitable/getTable',
    }),
  },
  mounted() {
    this.localData = {};
    this.structure.forEach(item => {
      this.localData[item.key] = this.targetData[item.key];
    })
    this.localData = {...this.localData};
  },
  methods: {
    selectValue(table, key, item) {
      return this.localData[key];
    },
    stringValue(key) {
      if (!this.localData[key]) {
        return '';
      }
      return this.localData[key].value ? this.localData[key].value.replace('\'', '') : this.localData[key].value;
    },
    insertString(value, key) {
      this.localData[key] = {
        type: 'LIKE',
        value,
      }
    },
    insertBigNumber(val, key) {
      this.localData[key] = val
          .toString()
          .split('')
          .map(Number)
          .filter(i => !Number.isNaN(i))
          .join('');
      window.filter = this;
      this.localData = { ...this.localData };
    },
    insertSelect(val, key) {
      this.localData[key] = val;

      this.localData = { ...this.localData };
    },
    reset() {
      this.localData = {};
      this.$emit('search', {});
    },
    refresh() {
      this.localData = {};
      this.$emit('sort', {});
      this.$emit('search', {});
    },
    search() {

      const result = R.pipe(
          R.toPairs,
          R.filter(i => {
            if (typeof i[1] === 'undefined') return false;

            if (i[1] === '') return false;

            return true;
          }),
          R.fromPairs
      )({...this.targetData, ...this.localData});

      this.$emit('search', result);
      //alert(JSON.stringify(this.targetData));
    },

    selectOptions(table, filter_by, depends_on) {
      try {
        const currentTable = tables[table]();

        const middlewares = [];

        middlewares.push(this.getTable);
        middlewares.push(R.defaultTo([]));

        if (filter_by) {
          middlewares.push(R.filter(item => item[filter_by] === this.localData[filter_by]))
        }

        if (depends_on) {
          Object.entries(depends_on)
              .filter(([localKey]) => this.localData[localKey])
              .filter(([localKey]) => this.localData[localKey].length)
              .forEach(([localKey, sourceKey]) => {
                    middlewares.push(R.filter(item => this.localData[localKey].includes(item[sourceKey].toString())))
                  }
              )
        }
        const indexes = filter_by ?
            currentTable.indexField.filter(item => item !== filter_by) :
            [...currentTable.indexField];

        middlewares.push(R.map(item => ({
          name: item[currentTable.viewField],
          value: indexes.length > 1 ? indexes.map(i => `${i}:${item[i]}`).join('/') : item[indexes[0]],
        })));

        return R.pipe(...middlewares)(table);

      } catch (e) {
        console.error(e);
      }
    },
  }
}
</script>

<style lang="stylus" scoped>
.filter__container
  display flex
  margin 10px

.filter__button
  margin 0 10px !important

.reset__button
  margin-left 0 !important

.input__column
  margin-right 10px !important
</style>
