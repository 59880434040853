export default {
    tableName: 'Типы транспорта',
    tableCode: 'transporttypes',
    indexField: ['id'],
    viewField: 'description',
    defaultOrder: {
        id: 'ASC',
    },
    tableDescription: [
        {
            type: BigInt,
            name: 'Идентификатор',
            key: 'id',
            width: 150,
        },
        {
            type: String,
            key: 'description',
            name: 'Тип транспорта'
        },
    ]
};
