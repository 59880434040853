<template lang="pug">
  el-container.column
    slot(name="modal" :set-loading="setLoading" :table-code="tableCode")

    content-modal(
      :opened.sync="contentModal.opened"
      :mode="contentModal.mode"
      :structure="contentModal.structure"
      :values.sync="contentModal.values"
      :table-code="tableCode"
      v-on:update-success="updateSuccess"
    )
    import-c-s-v(
      :visible="showImportCSV"
      :data='importModal'
      :table-code='tableCode'
      v-on:set-loading='setLoading'
      v-on:update:visible="val => showImportCSV = val"
      v-on:update-success="updateSuccess"
    )


    arm-header.header(v-loading="loading")

      slot(name="import" :set-loading="setLoading" :auth-token="authToken" :stop-loading="stopLoading" :loadCSV="loadCSV")

      el-upload.upload-csv(
        v-if='!disableOperations && !CSVImportDisabled'
        ref="upload"
        :headers="{authorization: authToken()}"
        action="/api/v1/files/add"
        :before-upload="setLoading"
        accept="text/csv"
        :on-success="loadCSV"
      ): el-button(
        icon='el-icon-upload2'
        /*v-on:click="setLoading"*/
      ) Импорт CSV

      a.left__margin(target="_blank" :href="exportCSVUrl"): el-button(
        icon="el-icon-download"
      ) Экспорт CSV

      slot(name="dropdown" :filters="filters" :group_by="group_by" :set-group-by="setGroupBy")

      el-button.left__margin(
        v-if='!disableOperations'
        icon='el-icon-circle-plus-outline'
        type="success"
        v-on:click="openCreateModal"
      ) Добавить ряд

    uni-table-filters(
      v-if="!loading"
      :structure="tableDescription"
      :target-data="filters"
      v-on:search="applyFilters"
      v-on:sort="resetSort"
    )
    el-menu.scroll-menu(ref="dataTable")
      el-table(
        v-loading="loading"
        :data="enrichedTable"
        :default-sort="defaultSort"
        border
        ref="mainTable"
        v-on:sort-change="applySort"
        v-on:selection-change="handleSelectionChange"
      )
        el-table-column(
          v-if="multipleChoice"
          type="selection"
        )

        el-table-column(
          v-for="column in tableDescription"
          :key="column.key"
          :prop="column.key"
          :label="column.name"
          v-if="!column.column_hidden"
          :width='column.width'
          sortable="custom"
        )
          template(slot-scope="data")
            template(v-if="column.type === FLCResult")
              .success(v-if="data.row[column.key] === 'true'")
                | ok
              .error(v-else) Ошибка
            template(v-else-if="column.type === Boolean")
              | {{ data.row[column.key] ? 'Да' : 'Нет' }}
            template(v-else-if="column.decorator_in")
              | {{ column.decorator_in(data.row[column.key]) }}
            template(v-else-if="column.type !== Date")
              | {{data.row[column.key]}}
            template(v-else)
              | {{ data.row[column.key] | customDate }}
        el-table-column(
          v-if="!disableOperations"
          label="Действия"
          align="center"
          header-align="center"
          width="120"
        )
          template(slot-scope="data")
            el-row(style="justify-content: space-around; display: flex;")
              el-button(type="warning" v-on:click="editItem(data.row)" plain circle icon="el-icon-edit")
              el-popconfirm(
                title="Вы уверены что хотите удалить?"
                confirm-button-text="Да"
                cancel-button-text="Нет"
                v-on:confirm="removeWrapper(data.row)"
              )
                el-button(slot="reference" type="danger" circle plain icon="el-icon-delete")

    el-pagination.uni__pagination(
      background
      layout="sizes, prev, pager, next"
      :page-sizes="[10, 20, 50, 100]"
      :page-size.sync="pagination.pageSize"
      :current-page.sync="pagination.currentPage"
      v-on:current-change="paginationPageClick"
      v-on:size-change="paginationSizeChange"
      :total="getCount(tableCode)"
    )
</template>

<script>
import * as R from 'ramda';
import {mapGetters} from "vuex";
import ArmHeader from "@/components/ArmHeader";
import {sleep} from "@/utils/sleep";
import TablesStore from '@/tables';
import ContentModal from "@/pages/UniversalTable/ContentModal";
import UniTableFilters from "@/pages/UniversalTable/Filters";
import ImportCSV from "@/pages/UniversalTable/ImportCSV";
import sortTransform from "@/utils/sortTransform";
import FLCResult from '@/tables/Types/FLCResult';


export default {
  name: 'UniversalTable',
  components: {ImportCSV, UniTableFilters, ContentModal, ArmHeader},
  props: {
    tableCode: { // table name in pg
      type: String,
      required: true,
    },
    CSVImportDisabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    tableDescription: { // table name in russian
      type: Array,
    },
    infiniteLength: { // table length is uncountable
      required: false,
      default: false,
      type: Boolean,
    },
    multipleChoice: {
      required: false,
      default: false,
      type: Boolean,
    },
    disableOperations: { // don't allow edit/remove ops on table
      required: false,
      default: false,
      type: Boolean,
    },
    indexField: { // field which should act as index on edit/remove ops
      type: Array,
      default: () => ([]),
      required: false,
    },
    defaultOrder: {
      type: Array,
      required: false,
    },
    group_by: {
      type: Array,
      required: false,
      default: () => ([])
    },
    encrypt: {
      type: Boolean,
      default: false,
      required: false,
    }
  },
  data: () => ({
    importModal: {},
    FLCResult,
    contentModal: {
      opened: false,
      mode: 'edit',
      structure: [],
      values: {},
      tableCode: undefined,
    },
    showImportCSV: false,
    customSort: {},
    internalTableCode: undefined,
    filters: {},
    // group_by: [],
    loading: false,
    pagination: {
      pageSize: 20,
      currentPage: 1,
    },
    deleteArray: [],
  }),
  computed: {
    exportCSVUrl() {
      if (this.encrypt) {
        return `/api/v1/storage/${this.tableCode}/csv?authorization=${this.authToken()}&encrypt=true`;
      }
      return `/api/v1/storage/${this.tableCode}/csv?authorization=${this.authToken()}`
    },
    serverSort() {
      // console.log(this.customSort, Object.entries(this.customSort).length, this.defaultOrder);
      const result = Object.entries(this.customSort).length ? this.customSort : this.defaultOrder;

      console.log('serverSort returns ', result);

      return result;
    },
    defaultSort() {
      const sort = this.serverSort;

      if (!sort || Object.keys(sort).length === 0) return {};

      if (Array.isArray(sort)) {
        return {
          prop: sort[0].key,
          order: sort[0].type === 'ASC' ? 'ascending' : 'descending',
        };
      }
      const [prop, order] = Object.entries(sort)[0];

      return {
        prop,
        order: order === 'ASC' ? 'ascending' : 'descending',
      };
    },
    ...mapGetters({
      getTable: 'unitable/getTable',
      getCount: 'unitable/getCount',
      getTableRow: 'unitable/getTableRow',
    }),
    enrichedTable() {
      return this.getTable(this.internalTableCode) ? this.getTable(this.internalTableCode)
          .map(item => {
            const anotherItem = {...item};
            Object.entries(item).forEach(([key, value]) => {
              const description = this.tableDescription.find(i => i.key === key);

              if (!description) {
                // console.log(`Unknown ${key} for ${this.tableCode}`);
                return;
              }

              if (!description.relation) return;

              const {relation} = description;

              if (!relation) {
                return;
              }


              const buffer = [];

              [...new Set(value.toString().split(','))].forEach(itemValue => {

                const indexValues = {};


                Object.entries(relation.join_rules).forEach(([thisField, targetField]) => {

                  indexValues[targetField] = thisField === key ? itemValue : item[thisField];
                });

                try {
                  // console.log(indexValues);
                  const row = this.getTableRow(relation.table, {
                    search: Object.values(relation.join_rules),
                    values: indexValues,
                  });

                  buffer.push(row[relation.view]);
                } catch (e) {
                  // console.error(e);
                  buffer.push(`${itemValue} (Отсутствует в справочнике)`);
                }
              })

              // console.log(JSON.stringify(anotherItem[key]));
              if (description.multiple_separator) {
                anotherItem[key] = buffer.join(description.multiple_separator);
              } else {
                anotherItem[key] = buffer.join('\n');
              }

            })


            anotherItem.source = {...item};


            return anotherItem;
          }) : [];
    },
    paginationForRequest() {
      return {
        offset: (this.pagination.currentPage - 1) * this.pagination.pageSize,
        limit: this.pagination.pageSize,
      };
    },
  },
  methods: {
    setGroupBy(value) {
      this.group_by = value;
    },
    authToken() {
      return localStorage.getItem('authorization');
    },
    loadCSV(response) {
      this.loading = false
      this.showImportCSV = true;
      this.importModal = response;
    },
    async applySort({prop, order}) {
      if (!order) {
        this.customSort = {};
      } else {
        this.customSort = {
          [prop]: order === 'ascending' ? 'ASC' : 'DESC',
        }
      }

      await this.fetchTable();
    },
    setLoading() {
      console.log('here');
      this.loading = true;
    },
    startLoading() {
      this.loading = true;
    },
    stopLoading() {
      this.loading = false;
    },
    resetSort() {
      this.customSort = {};
      this.$refs.mainTable.clearSort();
      // console.log(this.defaultSort);
      this.$refs.mainTable.sort(this.defaultOrder[0].prop, this.defaultOrder[0].order);
    },
    async applyFilters(filters) {
      this.filters = filters;
      this.pagination = {
        ...this.pagination,
        currentPage: 1,
      }
      await this.fetchTable();
    },
    async updateSuccess() {
      await this.fetchTable();
    },
    async removeWrapper(row) {
      if (this.deleteArray.length === 0) {
        return this.removeItem(row);
      }
      await Promise.all(
          this.deleteArray.map((row) => this.removeItem(row))
      );
    },
    async removeItem(row) {
      const primary_key = R.pick(this.indexField)(row.source);

      await this.$store.dispatch('unitable/removeItem', {
        table_name: this.internalTableCode,
        primary_key,
      })

      await this.fetchTable();

      this.$message({
        type: 'success',
        message: 'Удаление успешно',
        showClose: true,
      });
    },
    editItem(scope) {
      this.contentModal = {
        opened: true,
        mode: 'edit',
        structure: this.tableDescription,
        values: scope,
        tableCode: this.tableCode,
      };
    },
    openCreateModal() {
      this.contentModal = {
        opened: true,
        mode: 'add',
        structure: this.tableDescription,
        values: {},
        tableCode: this.tableCode,
      };
    },
    async paginationPageClick() {
      await this.fetchTable();
    },
    async paginationSizeChange() {
      this.pagination.currentPage = 1;
      await this.fetchTable();
    },
    async fetchTable() {
      this.loading = true;

      const default_order = Array.isArray(this.serverSort) ?
          this.serverSort :
          sortTransform(this.serverSort);

      console.log(this.serverSort, Array.isArray(this.serverSort));

      await this.$store.dispatch('unitable/fetchTable', {
        table_name: this.internalTableCode,
        infinite_length: this.infiniteLength,
        default_order,
        filters: this.filters,
        group_by: this.group_by,
        ...this.paginationForRequest,
      })

      this.rerender();
      document.querySelector('.scroll-menu').scrollTo(0, 0);
      await sleep(1e2);
      this.loading = false;
    },
    rerender() {
      // hack for page rerender
      this.internalTableCode = null;
      this.$nextTick(() => {
        this.internalTableCode = this.tableCode;
      });
    },
    handleSelectionChange(val) {
      this.deleteArray = val;
    }
  },
  async mounted() {
    this.internalTableCode = this.tableCode;

    await this.fetchTable();
    this.loading = true;

    try {
      const tables = R.pipe(
          R.prop('tableDescription'),
          R.map(R.prop('relation')),
          R.filter(R.pipe(R.not, R.not)),
          R.map(R.prop('table')),
      )(this);

      const schemas = tables.map(t => TablesStore[t]());

      const localTables = schemas.filter(s => s.local);
      const remoteTables = schemas.filter(s => !s.local);

      console.log('localTabes', localTables);

      localTables.forEach(table => {
        this.$store.commit('unitable/writeToStorage', {
          data: table.content,
          table_name: table.tableCode,
        });
        this.$store.commit('unitable/writeCountToStorage', {
          count: table.content.length,
          table_name: table.tableCode,
        });
      })


      await Promise.all(
          remoteTables.map(table => this.$store.dispatch('unitable/fetchTable', {
            table_name: table.tableCode
          }))
      );
    } finally {
      this.loading = false;
    }

    this.rerender();
  },
}
</script>

<style lang="stylus" scoped>

.left__margin
  margin-left 10px

.column
  height 100vh
  overflow-y scroll

.scroll-menu
  height calc(100vh - 180px)
  overflow-y scroll

.uni__pagination
  padding-top 20px
  text-align center

.upload-csv
  display inline-block
</style>

<style lang="stylus">
.el-loading-spinner
  top calc(50vh - 25px) !important

.el-upload-list
  display none

.success
  color green

.error
  color red
</style>
