import {sort} from "@/constants";

export default {
    tableCode: 'job_title',
    tableName: 'Уполномоченные лица',
    indexField: ['id'],
    viewField: 'description',
    defaultOrder: {
        id: sort.ASC,
    },
    tableDescription: [
        {
            key: 'id',
            name: 'ID',
            type: BigInt,
        },
        {
            key: 'description',
            type: String,
            name: 'Должность',
        },
    ]
}
