import FLCResult from "@/tables/Types/FLCResult";

export default {
    tableName: 'Временная таблица ЧС внешних источников',
    tableCode: 'blacklistflc_ext',
    indexField: [
        'card_number'
    ],
    defaultOrder: {
        resultflk: 'ASC',
        card_number: 'ASC',
    },
    tableDescription: [
        {
            type: BigInt,
            key: 'card_number',
            name: '№ Карты',
        },
        {
            type: FLCResult,
            key: 'card_number_flcresult',
            name: 'Результат ФЛК № карты',
        },
        {
            type: String,
            key: 'date_time',
            name: 'Дата и время блокировки'
        },
        {
            type: FLCResult,
            key: 'date_time_flcresult',
            name: 'Результ ФЛК даты'
        },
        {
            type: String,
            key: 'add_remove_flag',
            name: 'Флаг включения'
        },
        {
            type: FLCResult,
            key: 'add_remove_flag_flcresult',
            name: 'Результ ФЛК флага включения'
        },
        {
            type: String,
            key: 'reason',
            name: 'Причина блокировки',
            relation: {
                table: 'reasonsdata',
                view: 'description',
                join_rules: {
                    reason: 'id_reasons',
                },
            },
        },
        {
            type: FLCResult,
            key: 'reason_flcresult',
            name: 'ФЛК причины блокировки',
        },
        {
            type: FLCResult,
            key: 'copy_flag_flcresult',
            name: 'ФЛК копии основной таблицы'
        },
        {
            type: FLCResult,
            key: 'resultflk',
            name: 'Общий результат ФЛК'
        },
    ],
}
