<template lang="pug">
  el-container.column
    content-modal(
      :opened.sync="contentModal.opened"
      :mode="contentModal.mode"
      :values.sync="contentModal.values"
      :roles="roles"
      v-on:update-success="updateSuccess"
    )

    arm-header.header
      el-select.select__style(
        v-model="selectedRoles"
        filterable
        clearable
        placeholder="Выберите группу"
      )
        el-option(
          v-for="item in roles"
          :key="item.value"
          :label="item.name"
          :value="item.value"
        )
      el-button(
        type="success"
        icon="el-icon-circle-plus-outline"
        v-on:click="openAddModal"
      ) Добавить

    el-table(
      :data="tableData"
      border
    )
      el-table-column(
        v-for="column in tableDescription"
        :key="column.key"
        :prop="column.key"
        :label="column.name"
        :width="column.width"
      )

      el-table-column(
        header-align="center"
        align="center"
        label="Действия"
      )
        template(slot-scope="data")
          el-row(style="justify-content: space-around; display: flex;")
            el-button(type="warning" v-on:click="openEditModal(data.row)" circle plain): i.el-icon-edit
            el-popconfirm(
              title="Вы уверены что хотите удалить?"
              confirm-button-text="Да"
              cancel-button-text="Нет"
              v-on:confirm="removeItem(data.row)"
            )
              el-button(slot="reference" type="danger" circle plain): i.el-icon-delete
</template>

<script>
import ArmHeader from "@/components/ArmHeader";
import UniversalTable from "@/pages/UniversalTable";
import {mapGetters} from "vuex";
import ContentModal from "@/pages/Users/ContentModal";
import formatDate from "@/utils/formatDate";

export default {
  name: "ReasonsData",
  components: {
    ContentModal,
    UniversalTable,
    ArmHeader,
  },
  data: () => ({
    selectedRoles: undefined,
    contentModal: {
      opened: false,
      mode: 'edit',
      structure: [],
      values: {},
      tableCode: undefined,
    },
    tableDescription: [
      {
        key: 'id',
        name: 'ID',
        width: 80,
      },
      {
        key: 'login',
        name: 'Логин'
      },
      {
        key: 'name',
        name: 'Имя'
      },
      {
        key: 'surname',
        name: 'Фамилия'
      },
      {
        key: 'group_type',
        name: 'Группа'
      },
      {
        key: 'registration_date',
        name: 'Дата регистрации'
      },
    ],
    roles: [
      {
        value: 'administrator',
        name: 'Администратор'
      },
      {
        value: 'blacklist_operator',
        name: 'Оператор ЧС',
      },
      {
        value: 'nsi_operator',
        name: 'Оператор НСИ',
      },
      {
        value: 'reports_operator',
        name: 'Оператор Отчетов',
      },
    ]
  }),
  computed: {
    tableData() {
      return this.usersList.map(user => ({
        ...user,
        group_type: this.roles.find(r => r.value === user.group_type).name,
        source: user,
        registration_date: formatDate(user.registration_date),
      })).filter(item => {
        if (!this.selectedRoles) return true;
        return this.selectedRoles === item.source.group_type;
      })
    },
    ...mapGetters({
      usersList: 'admin/usersList',
    })
  },
  methods: {
    // async editItem(row) {
    //   await this.$store.dispatch('admin/editItem', row.id)
    //   await this.fetch();
    // },
    async removeItem(row) {
      await this.$store.dispatch('admin/removeItem', row.id)
      await this.fetch();
    },
    fetch() {
      this.$store.dispatch('admin/getList');
    },
    updateSuccess() {
      this.fetch();
    },
    openAddModal() {
      this.contentModal = {
        opened: true,
        mode: 'add',
        values: {},
      }
    },
    openEditModal(row) {
      const data = {...row}
      delete data.source.password;
      delete data.source.last_login;
      delete data.source.registration_date;

      this.contentModal = {
        opened: true,
        mode: 'edit',
        values: data,
      }
    },
  },
  mounted() {
    this
        .fetch();
  }
}
</script>

<style scoped lang="stylus">
.column
  flex-direction column

.select__style
  margin-right 10px !important
</style>
