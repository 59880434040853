import axios from "@/utils/axios";


const wrapPayload = filters => {
    const payload = ({...filters });

    Object.keys(payload).forEach(key => {

        if (payload[key] instanceof Date) {

            // console.log((new Date(payload[key].valueOf() + 3 * 60 * 60 * 1e3)).toJSON())

            payload[key] = (new Date(payload[key].valueOf() + 3 * 60 * 60 * 1e3)).toJSON();
        }

        if (typeof payload[key] !== 'string') return;

        const l = payload[key].split('/');

        if (l.length === 1) return;

        l.map(i => i.split(':')).forEach(([key, value]) => {
            payload[key] = value;
        })
    })

    return payload;
}

export const fetchTable = async ({ commit }, { table_name, filters, ...params}) => {
    const _filters = wrapPayload(filters);

    const { data: { data, count } } = await axios.post(
        `storage/${table_name}/get`,
        { filters: _filters, ...params},
    );

    commit('writeToStorage', { data, table_name });
    commit('writeCountToStorage', { count, table_name });
};


export const removeItem = (_, { table_name, primary_key }) =>
    axios.post(`storage/${table_name}/delete_row`, primary_key)


export const addItem = (_, { table_name, payload }) =>
    axios.post(`storage/${table_name}/add_row`, {
        payload: wrapPayload(payload),
    });

export const editItem = (_, { table_name, primary_key, payload }) =>
    axios.post(`storage/${table_name}/edit_row`, {
        payload: wrapPayload(payload), primary_key: wrapPayload(primary_key),
    });


export const fileOperations = async (_, { table_name, operation, filepath, indexFields }) => {

    console.log(indexFields);

    axios.defaults.timeout = 5 * 60e3;

    const { data } = await axios.post(`storage/${table_name}/csv`, {
        operation, filepath, indexFields,
    });

    return data;
    // axios.defaults.timeout = 10e3;
}

