export default {
    local: true,
    tableCode: 'extsalestemplates',
    tableName: 'extsalestemplates',
    indexField: ['template'],
    defaultOrder: {
        template: 'ASC',
    },
    viewField: 'template',
    tableDescription: [
        {
            type: String,
            key: 'template',
            name: 'Шаблон',
        }
    ],
    content: [
        { template: 'nositel' },
        { template: 'kb1' },
        { template: 'au' },
    ]
}
