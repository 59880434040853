export default {
    tableName: 'Действующие правила приема ПБ',
    tableCode: 'acceptrules',
    indexField: [
        'acceptrule_id',
    ],
    defaultOrder: {
        acceptrule_id: 'ASC',
    },
    tableDescription: [

        {
            type: BigInt,
            key: 'acceptrule_id',
            name: 'Правила приема ПБ',
            relation: {
                table: 'acceptrulesdict',
                view: 'name',
                // filter_by: 'category_id',
                join_rules: {
                    acceptrule_id: 'acceptrule_id',
                }
            }
        },
        {
            type: BigInt,
            key: 'category_id',
            name: 'Категория ПБ',
            relation: {
                table: 'cardcategories',
                view: 'name',
                join_rules: {
                    category_id: 'category_id',
                }
            }
        },
        {
            key: 'transporttype_id',
            type: BigInt,
            name: 'Типы транспорта',
            multiple: true,
            filter_hidden: true,
            must_clear: [
                'route_type',
                'id_customer',
                'carrier_id',
                'route_id',
            ],
            relation: {
                table: 'transporttypes',
                view: 'description',
                join_rules: {
                    transporttype_id: 'id',
                },
            },
        },
        {
            key: 'route_type',
            type: BigInt,
            name: 'Типы маршрутов',
            multiple: true,
            filter_hidden: true,
            must_clear: [
                'id_customer',
                'carrier_id',
                'route_id',
            ],
            depends_on: [
                'transporttype_id',
            ],
            relation: {
                table: 'routetypes',
                view: 'description',
                limit_by: {
                    routesdata: {
                        fields: {
                            transporttype_id: 'id_transport_type',
                        },
                        target: 'route_type'
                    }
                },
                join_rules: {
                    route_type: 'route_type',
                },
            },
        },
        {
            type: BigInt,
            name: 'Закзачики перевозок',
            key: 'id_customer',
            filter_hidden: true,
            multiple: true,
            must_clear: [
                'carrier_id',
                'route_id',
            ],
            depends_on: [
                'transporttype_id',
                'route_type',
            ],
            relation: {
                table: 'transportcustomers',
                view: 'name',
                join_rules: {
                    id_customer: 'id_customer',
                },
                limit_by: {
                    routesdata: {
                        fields: {
                            transporttype_id: 'id_transport_type',
                            route_type: 'route_type',
                        },
                        target: 'id_customer'
                    }
                },
            }
        },
        {
            key: 'carrier_id',
            type: BigInt,
            name: 'Перевозчики',
            multiple: true,
            filter_hidden: true,
            must_clear: [
                'route_id',
            ],
            depends_on: [
                'transporttype_id',
                'route_type',
                'id_customer',
            ],
            relation: {
                table: 'carriersdata',
                view: 'carrier_name',
                join_rules: {
                    carrier_id: 'id'
                },
                limit_by: {
                    routesdata: {
                        fields: {
                            transporttype_id: 'id_transport_type',
                            route_type: 'route_type',
                            id_customer: 'id_customer',
                        },
                        target: 'id_carrier',
                        compare: 'id',
                    }
                },
            }
        },
        {
            key: 'route_id',
            type: BigInt,
            name: 'Маршруты',
            multiple: true,
            filter_hidden: true,
            multiple_separator: ', ',
            depends_on: [
                'transporttype_id',
                'route_type',
                'id_customer',
                'carrier_id',
            ],
            relation: {
                table: 'routesdata',
                view: 'route_name',
                limit_by: {
                    routesdata: {
                        fields: {
                            transporttype_id: 'id_transport_type',
                            route_type: 'route_type',
                            id_customer: 'id_customer',
                            carrier_id: 'id_carrier',
                        },
                        target: 'id'
                    }
                },
                join_rules: {
                    route_id: 'id',
                }
            }
        },
        {
            key: 'date_begin',
            type: Date,
            name: 'Начало приема',
            filter_hidden: true,
        },
        {
            key: 'date_end',
            type: Date,
            name: 'Окончание приема',
            filter_hidden: true,
        }
    ]
}
