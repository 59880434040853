export default {
    tableCode: 'sourcesystems',
    tableName: 'Справочник модулей ЦОТ',
    indexField: ['source_system'],
    viewField: 'system_name',
    tableDescription: [
        {
            type: BigInt,
            name: 'Идентификатор ПО',
            key: 'source_system',
            width: 180,
        },
        {
            key: 'system_name',
            type: String,
            name: 'Наименование ПО',
        },
    ],
    defaultOrder: {
        source_system: 'ASC',
    }
};
