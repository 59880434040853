<template lang="pug">
  el-container.login-form
    el-card
      el-form(ref="form" :model="form" label-width="120px")
        el-form-item
          span.name АРМ Оператора
        el-form-item(label="Логин:")
          el-input(v-model="form.login" placeholder="Логин")
        el-form-item(label="Пароль:")
          el-input(v-model="form.password" type="password" placeholder="Пароль")
        el-form-item
          el-button(v-on:click="login" type="primary") Войти

</template>

<script>
export default {
  name: "NSILoign",
  data: () => ({
    form: {
      login: undefined,
      password: undefined,
    }
  }),
  methods: {
    async login() {
      try {
        await this.$store.dispatch('login', this.form)
        this.$router.push('/check-allowed')
            .catch(() => {}); // ignore errors from here
      } catch (e) {
        console.log(e);
        this.$notify({
          title: 'Ошибка входа',
          message: 'Неверные логин или пароль',
          type: 'warning'
        });
      }
    }
  }
}
</script>

<style scoped lang="stylus">
  .login-form
    justify-content center
    align-items center
    height 80vh
    font-weight bold
</style>
