export default {
    tableCode: 'requesttypes',
    tableName: 'Справочник типов запросов',
    indexField: ['request_type'],
    tableDescription: [
        {
            type: BigInt,
            name: 'Код заголовка пакета',
            key: 'request_type',
            width: 200,
        },
        {
            key: 'request_name',
            type: String,
            name: 'Описание заголовка пакета',
        },
    ],
    defaultOrder: {
        request_type: 'ASC',
    }
};
