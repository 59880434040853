<template lang="pug">
  universal-table(
    :table-code="tableCode"
    :table-name="tableName"
    :index-field="indexField"
    :table-description="tableDescription"
    :default-order="defaultOrder"
  )

    template(v-slot:import="{ setLoading, authToken, stopLoading }")
      el-upload.upload-controls__blackist(
        ref="upload"
        :headers="{authorization: authToken()}"
        action="/api/v1/files/add"
        :before-upload="setLoading"
        accept="text/csv"
        :on-success="r => showReestrData(r, stopLoading)"
      ): el-button(
        icon='el-icon-upload'
        type="info"
      ) Загрузить реестр ЧС

      el-upload.upload-controls__blackist.last(
        ref="upload"
        :headers="{authorization: authToken()}"
        action="/api/v1/files/add"
        :before-upload="setLoading"
        accept="text/csv"
        style="margin-right: 10px"
        :on-success="r => showExternalData(r, stopLoading)"
      ):  el-button(
        icon='el-icon-upload'
        type="info"
      ) Загрузить внешние данные

    template(v-slot:dropdown)
      el-button.export__dat(
        icon='el-icon-download'
        slot="trigger"
        type="info"
        v-on:click="showModal = true"
      ) Экспорт DAT

    template(v-slot:modal)
      el-dialog#form_black_list(title="Выгрузка BlackList.dat" :visible.sync="showModal" v-if="showModal")
        el-form(:model="form")
          el-form-item(label="Количество номеров:" :label-width="formLabelWidth")
            el-input(v-model="form.count" type="number" )
          el-form-item(label="Перечень типов ПБ:" :label-width="formLabelWidth")
            el-select(
              v-model="form.card_type"
              placeholder="Выберите типы ПБ"
              multiple
              clearable
            )
              el-option(
                v-for="item in cardsdataunique"
                :key="item.value"
                :label="item.label"
                :value="item.value")
        span.dialog-footer(slot="footer")
          el-button(v-on:click="showModal = false") Отмена
          el-button(type="primary" v-on:click="formDatFile") Сформировать

      el-dialog(:visible.sync="showTableModal")#form_black_list_table
        el-table(
          :data="datRecords"
          border
          height="80vh"
        )
          el-table-column(
            v-for="column in tableDescription"
            :key="column.key"
            :prop="column.key"
            :label="column.name"
            :sortable="true"
          )

</template>

<script>
import ArmHeader from "@/components/ArmHeader";
import UniversalTable from "@/pages/UniversalTable";
import tables from "@/tables";
import { mapGetters } from "vuex";
import axios from "@/utils/axios";
import {ROUTE_CONSTANTS} from "@/router";

export default {
  name: 'Blacklist',
  components: {
    UniversalTable,
    ArmHeader,
  },
  data: () => ({
    ...tables.blacklist(),
    showModal: false,
    showTableModal: false,
    formLabelWidth: '200',
    form: {
      count: 0,
      card_type: [],
    },
    datRecords: [],
  }),
  computed: {
    ...mapGetters({
      getTable: 'unitable/getTable',
    }),
    cardsdataunique() {
      const table = this.getTable('cardsdataunique');
      return table.map(item => ({
        label: item.card_description,
        value: item.card_type,
      }));
    }
  },
  methods: {
    async showReestrData(r, stopLoading) {
      console.log(r);
      await axios.post('/blacklist-reg', {
        filepath: r.filepath,
      })

      stopLoading();
      this.$router.push(ROUTE_CONSTANTS.NSI_BLACKLIST_REG);

    },
    async showExternalData(r, stopLoading) {
      console.log(r);
      await axios.post('/blacklist-ext', {
        filepath: r.filepath,
      })

      stopLoading();
      this.$router.push(ROUTE_CONSTANTS.NSI_BLACKLIST_EXT);
    },
    async formDatFile() {
      const { count, card_type } = this.form;

      await axios.post(`/blacklist/dat/get`, {
        count, card_type,
      });

      await this.$router.push(ROUTE_CONSTANTS.NSI_BLACKLIST_DAT_BUFFER);
      this.$notify({
        message: 'Вы находитесь в редакторе DAT файла',
        type: 'success',
      })
    },
  },
}
</script>

<style scoped lang="stylus">
.column
  flex-direction column

.export__dat
  margin-left 10px

.upload-controls__blackist
  display inline-block
  &.last
    margin-left 10px

</style>
<style lang="stylus">
#form_black_list
  .el-dialog
    width 40%
  .el-dialog__footer
    text-align center !important

#form_black_list_table
  .el-dialog
    height 100vh
    margin 0 !important
    margin-top 0 !important
    overflow-y scroll
    width 100vw


</style>
