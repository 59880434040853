export default {
    tableName: 'Справочник типов БО',
    tableCode: 'bo_types',
    indexField: ['bo_type'],
    viewField: 'description',
    defaultOrder: {
        bo_type: 'ASC',
    },
    tableDescription: [
        {
            type: BigInt,
            name: 'Идентификатор',
            key: 'bo_type',
        },
        {
            type: String,
            name: 'Наименование',
            key: 'description',
        }
    ]
}
