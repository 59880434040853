<template lang="pug">
  universal-table(
    :table-code="tableCode"
    :table-name="tableName"
    :index-field="indexField"
    :table-description="tableDescription"
    :default-order="defaultOrder"
  )
    template(v-slot:dropdown="{ filters }")
      a.export__dat(:href="downloadDatFile(filters)" target="_blank"): el-button(
        icon='el-icon-folder-add'
        slot="trigger"
        type="primary"
      ) Сохранить DAT

      el-button(type="info" v-on:click="back").export__dat Вернуться

</template>

<script>
import ArmHeader from "@/components/ArmHeader";
import UniversalTable from "@/pages/UniversalTable";
import tables from "@/tables";
import {ROUTE_CONSTANTS} from "@/router";

export default {
  name: 'BlacklistDatBuffer',
  components: {
    UniversalTable,
    ArmHeader,
  },
  data: tables.blacklist_dat_buffer,
  methods: {
    back() {
      this.$router.push(ROUTE_CONSTANTS.NSI_BLACKLIST_LIST);
    },
    downloadDatFile(filters) {
      return `/api/v1/blacklist/dat/save?filters=${JSON.stringify(filters)}` +
      `&authorization=${localStorage.getItem('authorization')}`;
    },
  }
}
</script>

<style scoped lang="stylus">
.column
  flex-direction column


.export__dat
  margin-left 10px
</style>
