import FLCResult from "@/tables/Types/FLCResult";

export default {
    tableName: 'Временная таблица реестра ЧС',
    tableCode: 'blacklistflc_reg',
    indexField: [
        'card_number'
    ],
    defaultOrder: {
        resultflk: 'ASC',
        card_number: 'ASC',
    },
    tableDescription: [
        {
            type: BigInt,
            key: 'card_number',
            name: '№ Карты',
        },
        {
            type: FLCResult,
            key: 'card_number_flcresult',
            name: 'ФЛК номера карты',
        },
        {
            type: String,
            key: 'block_date_time',
            name: 'Дата и время блокировки',
        },
        {
            type: FLCResult,
            key: 'block_date_time_flcresult',
            name: 'ФЛК даты и времени',
        },
        {
            type: String,
            key: 'unblock_date_time',
            name: 'Дата и время разблокировки',
        },
        {
            type: FLCResult,
            key: 'unblock_date_time_flcresult',
            name: 'ФЛК даты и времени',
        },
        {
            type: String,
            key: 'reason',
            name: 'Причина блокировки',
            relation: {
                table: 'reasonsdata',
                view: 'description',
                join_rules: {
                    reason: 'id_reasons',
                },
            },
        },
        {
            type: FLCResult,
            key: 'reason_flcresult',
            name: 'ФЛК причины блокировки',
        },
        {
            type: String,
            key: 'priority',
            name: 'Приоритет выгрузки',
            relation: {
                table: 'prioritydata',
                view: 'description',
                join_rules: {
                    priority: 'id_priority',
                },
            },
        },
        {
            type: FLCResult,
            key: 'priority_flcresult',
            name: 'ФЛК приоритета выгрузки',
        },
        {
            type: String,
            key: 'card_type',
            name: 'Тип ПБ',
            relation: {
                table: 'cardsdataunique',
                join_rules: {
                    card_type: 'card_type',
                },
                view: 'card_description',
            },
        },
        {
            type: FLCResult,
            key: 'card_type_flcresult',
            name: 'ФЛК типа ПБ'
        },
        {
            type: FLCResult,
            key: 'copy_flag_flcresult',
            name: 'ФЛК копии основной таблицы'
        },
        {
            type: FLCResult,
            key: 'resultflk',
            name: 'Общий результат ФЛК'
        },
    ]
}
