export default {
    tableName: 'Типы транспорта',
    tableCode: 'zonetariffs',
    indexField: ['id_zonetariff'],
    defaultOrder: {
        id_zonetariff: 'ASC'
    },
    tableDescription: [
        {
            type: BigInt,
            name: 'Код тарифа',
            key: 'id_zonetariff',
        },
        {
            type: Date,
            key: 'begin_date_time',
            name: 'Начало действия',
        },
        {
            type: Date,
            key: 'end_date_time',
            name: 'Окончание действия',
        },
        {
            type: BigInt,
            key: 'route_type',
            name: 'Тип маршрута',
            relation: {
                table: 'routetypes',
                view: 'description',
                join_rules: {
                    route_type: 'route_type',
                },
            },
        },
        {
            key: 'comment',
            name: 'Примечание',
            type: String,
        }
    ]
};
