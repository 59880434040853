export default {
    tableName: 'Справочник перевозчиков',
    tableCode: 'carriersdata',
    indexField: ['id'],
    defaultOrder: {
        id: 'ASC',
    },
    viewField: 'carrier_name',
    tableDescription: [
        {
            type: BigInt,
            name: 'Идентификатор',
            key: 'id',
            width: 150,
        },
        {
            type: String,
            name: 'Наименование',
            key: 'carrier_name',
        },
        {
            type: String,
            name: 'Адрес',
            key: 'contacts',
        },
        {
            type: String,
            name: 'Телефон',
            key: 'phone',
        },
        {
            type: String,
            name: 'Электронная почта',
            key: 'email',
        },
    ]
}
