<template lang="pug">
  universal-table#acceptrules(
    :table-code="tableCode"
    :table-name="tableName"
    :index-field="indexField"
    :table-description="tableDescription"
    :default-order="defaultOrder"
    :group_by="['acceptrule_id']"
  )

  //
    template(v-slot:dropdown="{ group_by, setGroupBy }")
      el-select.input__column(
        :value="group_by"
        v-on:input="value => setGroupBy(value)"
        filterable
        multiple
        collapse-tags
        placeholder="Группировать по"
        clearable
      )
        el-option(
          v-for="item in groupFields"
          :key="item.value"
          :label="item.name"
          :value="item.value"
        )

</template>

<script>
import ArmHeader from "@/components/ArmHeader";
import UniversalTable from "@/pages/UniversalTable";
import tables from "@/tables";

export default {
  name: "AcceptRules",
  components: {
    UniversalTable,
    ArmHeader,
  },
  data: () => ({
    ...tables.acceptrules(),
    groupFieldsValue: [],
  }),
  computed: {
    groupFields() {
      return [
        { name: 'Категории ПБ', value: 'category_id' },
        { name: 'Тип ПБ', value: 'card_type' },
        { name: 'Закзачики перевозо', value: 'id_customer' },
        { name: 'Перевозчики', value: 'carrier_id' },
        { name: 'Типы транспорта', value: 'transporttype_id' },
        { name: 'Типы маршрутов', value: 'route_type' },
        { name: 'Маршруты', value: 'route_id' },
      ]
    }
  },
  methods: {
    // selectValue(group_by) {
    //   return filters.group_by || [];
    // },
    // addToSelect(value, group_by) {
    //   group_by = value;
    //
    //   window.group_by = group_by;
    // },
  }
}
</script>

<style scoped lang="stylus">
.column
  flex-direction column

.input__column
  margin-left 10px
  width 250px


</style>

<style lang="stylus">
#acceptrules .el-table__cell .cell
  white-space pre-wrap !important

</style>
