<template lang="pug">
  el-drawer(
    :title="mode === 'edit' ? 'Редактирование пользователя' : 'Добавление пользователя'"
    :visible="opened"
    v-on:update:visible="v => $emit('update:opened', v)"
    direction="rtl"
    :before-close="beforeClose"
    custom-class="demo-drawer"
    ref="drawer"
    v-on:open="modalOpened"
  )

    .demo-drawer__content
      el-form
        el-form-item(label="Имя")
          el-input(
            placeholder="Имя"
            v-model="targetData.name"
          )
        el-form-item(label="Фамилия")
          el-input(
            placeholder="Фамилия"
            v-model="targetData.surname"
          )

        el-form-item(label="Логин")
          el-input(
            placeholder="Логин"
            v-model="targetData.login"
          )

        el-form-item(label="Группа")
          el-select(
            :value="selectValue('group_type')"
            v-on:input="value => updateSelect('group_type', value)"
            filterable
            placeholder="Группа"
          )
            el-option(
              v-for="item in roles"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            )

        el-form-item.hint(v-if="mode === 'edit'" label="Оставьте пароль пустым, если не хотите менять:")
        el-form-item(label="Пароль")
          el-input(
            placeholder="Пароль"
            v-model="targetData.password"
            type="password"
          )

        el-row.buttons__block
          el-button(v-on:click="save" type="primary") Сохранить
          el-button(type="warning" v-on:click="close" plain) Отмена

</template>

<script>
import * as R from 'ramda';
import { mapGetters } from "vuex";
import tables from "@/tables";

export default {
  name: 'ContentModal',
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    roles: {
      type: Array,
    },
    mode: {
      type: String,
      validate: (item) => ['edit', 'create'].includes(item),
    },
    structure: {
      type: Array,
    },
    values: {
      type: Object,
    },
    tableCode: {
      type: String,
      required: false,
    }
  },
  data: () => ({
    buffer: {},
    targetData: {},
    BigInt,
  }),
  computed: {
    ...mapGetters({
      getTable: 'unitable/getTable',
    }),
  },
  methods: {
    close() {
      this.$refs.drawer.closeDrawer();
    },
    selectValue(key) {
      return this.targetData[key] ? String(this.targetData[key]) : undefined;
    },
    updateSelect(key, value) {
      this.targetData[key] = value;
      this.targetData = { ...this.targetData };
    },
    modalOpened() {
      this.buffer = { ...this.values };
      this.targetData = { ...this.values.source };
    },
    async save() {

      if (this.mode === 'edit') {
        await this.$store.dispatch('admin/editItem', this.targetData);
      } else if (this.mode === 'add') {
        await this.$store.dispatch('admin/addItem', this.targetData);
      }
      this.$emit('update-success');
      this.close();
    },
    beforeClose(done) {
      done();
    }
  },
  mounted() {
  }
}
</script>

<style lang="stylus">

.el-input
  width auto !important

.el-form-item__label
  line-height auto !important
  width 200px

.buttons__block
  margin-left 20px
  text-align center

.hint
  display flex
  justify-content center
  .el-form-item__label
    line-height 20px
    font-weight bold
</style>
