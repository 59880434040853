<template lang="pug">

  el-dialog#import_csv(
    :title="data.originalFilename"
    :visible="visible"
    v-on:update:visible="val => $emit('update:visible', val)"
    v-on:close="closeModal"
    width="30%"
    v-loading="loading"
    center
  )
    el-col.inline__block
      el-row
        el-button(v-if='updateAllowed' type="warning" v-on:click="addToTable('update')") Обновить
      el-row.top-margin
        el-button(type="success" v-on:click="addToTable('add_ignore')") Добавить с игнорированием дубликатов
      el-row.top-margin
        el-button(type="success" v-on:click="addToTable('add_update')") Добавить с перезаписью дубликатов

</template>

<script>
import axios from '@/utils/axios';

export default {
  name: "SaveBlacklist",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
    },
    tableCode: {
      type: String,
    }
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    updateAllowed() {
      if (this.tableCode !== 'blacklist') return true;

      return this.$store.getters.role === 'administrator';
    },
  },
  methods: {

    async addToTable(operationName) {
      try {
        this.loading = true;

        let url = '';

        if (operationName === 'update') {
          url = `/${this.tableCode}/update?truncate=true`;
        } else if (operationName === 'add_update') {
          url = `/${this.tableCode}/update?replace=true`;
        } else if (operationName === 'add_ignore') {
          url = `/${this.tableCode}/update`;
        }

        console.log(url);
        await axios.post(url)

        this.loading = false;
        this.$emit('update:visible', false);
        this.$emit('update-success')
      } catch (e) {
        console.log(e);
        this.loading = false;
        this.$emit('update:visible', false);
      }
    },
    closeModal() {

    },
  }
}
</script>

<style scoped lang="stylus">
.inline__block
  display inline-block
.top-margin
  margin-top 20px
</style>

<style lang="stylus">
#import_csv .el-dialog__body
  display inline-block
</style>
