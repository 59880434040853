export default {
    tableName: 'Тарифы по банковским картам',
    tableCode: 'tariffs_bankcards',
    indexField: [
        'id'
    ],
    defaultOrder: {
        ['id']: 'ASC'
    },
    tableDescription: [
        {
            key: 'id',
            name: 'Идентификатор записи',
            type: BigInt,
        },
        {
            key: 'paymentsystem_id',
            name: 'Код платежной системы',
            type: String,
            relation: {
                table: 'paymentsystems',
                join_rules: {
                    paymentsystem_id: 'id'
                },
                view: 'description'
            }
        },
        {
            key: 'tariff',
            name: 'Тариф проезда, руб.',
            type: BigInt,
            decorator_in: value => ~~(value / 100),
            decorator_out: value => value * 100,
        },
        {
            key: 'baggage_tariff',
            name: 'Тариф провоза багажа, руб.',
            type: BigInt,
            decorator_in: value => ~~(value / 100),
            decorator_out: value => value * 100,
        },
        {
            key: 'date_begin',
            name: 'Дата начала действия тарифа',
            type: Date,
        },
        {
            key: 'date_end',
            name: 'Дата окончания действия тарифа',
            type: Date,
        },
        {
            key: 'note',
            name: 'Примечание',
            type: String,
        },
        {
            key: 'reg_date_time',
            name: 'Дата/время создания тарифа',
            type: Date,
        }
    ]
}
