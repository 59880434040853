<template lang="pug">
  universal-table(
    :table-code="tableCode"
    :table-name="tableName"
    :index-field="indexField"
    :table-description="tableDescription"
    :default-order="defaultOrder"
    :disable-operations="checkUserRole"
    :encrypt="true"
  )
</template>

<script>
import ArmHeader from "@/components/ArmHeader";
import UniversalTable from "@/pages/UniversalTable";
import tables from "@/tables";

export default {
  name: 'NSIExtSales',
  components: {
    UniversalTable,
    ArmHeader,
  },
  data: tables.extsales,
  computed: {
    checkUserRole() {
      const role = this.$store.getters.role;
      return role !== 'administrator';
    },
  },
}
</script>

<style scoped lang="stylus">
.column
  flex-direction column

</style>
