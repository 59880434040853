export default {
    tableName: 'Справочник занесения ПБ в ЧС',
    tableCode: 'reasonsdata',
    indexField: ['id_reasons'],
    viewField: 'description',
    defaultOrder: {
        id_reasons: 'ASC',
    },
    tableDescription: [
        {
            type: BigInt,
            name: 'Идентификатор',
            key: 'id_reasons',
            width: 150,
        },
        {
            type: String,
            name: 'Причина блокировки',
            key: 'description',
        }
    ]
};
