export default {
    tableName: 'Категории (правила гашения) ПБ',
    tableCode: 'cardcategories',
    indexField: ['category_id'],
    defaultOrder: {
        category_id: 'ASC',
    },
    viewField: 'name',
    tableDescription: [
        // {
        //     type: BigInt,
        //     name: 'Идентификатор',
        //     key: 'category_id',
        //     width: 150,
        // },
        {
            type: String,
            name: 'Наименование',
            key: 'name',
        },
        {
            type: BigInt,
            key: 'code',
            name: 'Код категории',
        }
    ]
}
