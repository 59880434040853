export default {
    tableName: 'Справочник маршрутов',
    tableCode: 'routesdata',
    viewField: 'route_name',
    indexField: ['id'],
    defaultOrder: {
        id: 'ASC',
    },
    tableDescription: [
        {
            type: BigInt,
            name: 'Идентификатор',
            key: 'id',
            width: 150,
        },
        {
            type: String,
            name: 'Наименование',
            key: 'route_name',
            width: 150,
        },
        {
            type: BigInt,
            name: 'Перевозчик',
            key: 'id_carrier',
            relation: {
                table: 'carriersdata',
                view: 'carrier_name',
                join_rules: {
                    id_carrier: 'id',
                },
            }
        },
        {
            type: BigInt,
            key: 'id_transport_type',
            name: 'Тип транспорта',
            relation: {
                table: 'transporttypes',
                view: 'description',
                join_rules: {
                    id_transport_type: 'id',
                },
            },
            width: 150,
        },
        {
            type: BigInt,
            key: 'route_type',
            name: 'Тип маршрута',
            relation: {
                table: 'routetypes',
                view: 'description',
                join_rules: {
                    route_type: 'route_type',
                },
            },
        },
        {
            type: BigInt,
            key: 'id_customer',
            name: 'Заказчик',
            relation: {
                table: 'transportcustomers',
                view: 'name',
                join_rules: {
                    id_customer: 'id_customer',
                }
            }
        },
        // {
        //     type: Date,
        //     key: 'activate_date',
        //     name: 'Дата активации',
        // },
        // {
        //     type: Date,
        //     key: 'block_date',
        //     name: 'Дата блокировки',
        // },
        {
            type: BigInt,
            key: 'ordertype',
            name: 'Тип заказа'
        },
        {
            key: 'note',
            type: String,
            name: 'Примечание'
        }
    ]
};
