<template lang="pug">
  universal-table(
    :table-code="tableCode"
    :table-name="tableName"
    :index-field="indexField"
    :table-description="tableDescription"
    :default-order="defaultOrder"
    :c-s-v-import-disabled="true"
    :multiple-choice="true"
  )
    template(v-slot:modal="{ setLoading, tableCode }")
      SaveBlacklist(
        :visible="showSaveBlackList"
        :data='blacklistModal'
        :table-code='tableCode'
        v-on:set-loading='setLoading'
        v-on:update:visible="val => showSaveBlackList = val"
        v-on:update-success="updateSuccess"
      )
    template(v-slot:import="{ loadCSV }")
      el-button(
        icon='el-icon-close'
        type="danger"
        plain
        v-on:click="$router.push('/nsi-blacklist')"
      ) Вернуться назад

      el-button(
        icon="el-icon-download"
        type="info"
        plain
        v-on:click="checkSaveAllowed"
      ) Сохранить

</template>

<script>
import ArmHeader from "@/components/ArmHeader";
import UniversalTable from "@/pages/UniversalTable";
import tables from "@/tables";
import { mapGetters } from "vuex";
import SaveBlacklist from "@/pages/UniversalTable/SaveBlacklist";
import {ROUTE_CONSTANTS} from "@/router";
import axios from "@/utils/axios";

export default {
  name: 'BlacklistExt',
  components: {
    SaveBlacklist,
    UniversalTable,
    ArmHeader,
  },
  data: () => ({
    ...tables.blacklistflc_ext(),
    showSaveBlackList: false,
    blacklistModal: {},
  }),
  computed: {
    ...mapGetters({
      getTable: 'unitable/getTable',
    }),
  },
  methods: {
    updateSuccess() {
      this.$router.push(ROUTE_CONSTANTS.NSI_BLACKLIST_LIST);
    },
    async checkSaveAllowed() {
      const r = await axios.get('/blacklistflc_ext/status')

      if (r.data.status === true) {
        this.showSaveBlackList = true
      } else {
        this.$notify({
          title: 'Ошибка сохранения',
          message: 'Исправьте все ошибки ФЛК перед сохранением!',
          type: 'warning'
        })
      }
    },
  },
}
</script>

<style scoped lang="stylus">
.column
  flex-direction column

.export__dat
  margin-left 10px

.upload-controls__blackist
  display inline-block
  &.last
    margin-left 10px

</style>
<style lang="stylus">
#form_black_list
  .el-dialog
    width 40%
  .el-dialog__footer
    text-align center !important

#form_black_list_table
  .el-dialog
    height 100vh
    margin 0 !important
    margin-top 0 !important
    overflow-y scroll
    width 100vw


</style>
