import {sort} from "@/constants";

export default {
    tableCode: 'clickhouse:Request_History',
    tableName: '',
    indexField: ['SOURCE_SYSTEM', 'ID_CARRIER', 'ID_TS'],
    defaultOrder: [
        {
            key: 'SOURCE_SYSTEM',
            type: sort.ASC,
        },
        {
            key: 'ID_CARRIER',
            type: sort.ASC,
        },
        {
            key: 'ID_TS',
            type: sort.ASC,
        }
    ],
    tableDescription: [
        {
            type: BigInt,
            name: 'Идентификатор ПО',
            key: 'SOURCE_SYSTEM',
            relation: {
                table: 'sourcesystems',
                view: 'system_name',
                join_rules: {
                    SOURCE_SYSTEM: 'source_system',
                }
            }
        },
        {
            key: 'SERVER_TYPE',
            type: BigInt,
            name: 'Идентификатор сервера',
        },
        {
            key: 'SERVER_ADDRESS',
            type: String,
            name: 'Адрес сервера',
        },
        {
            key: 'ID_CARRIER',
            type: BigInt,
            name: 'Код перевозчика',
            relation: {
                table: 'carriersdata',
                view: 'carrier_name',
                join_rules: {
                    ID_CARRIER: 'id',
                },
            },
        },
        {
            key: 'ID_TS',
            type: BigInt,
            name: 'Бортовой номер',
        },
        {
            key: 'ID_SECURITY_MODULE',
            type: String,
            name: 'Модуль безопасности',
        },
        {
            key: 'REQUEST_TYPE',
            type: BigInt,
            name: 'Тип запроса',
            relation: {
                table: 'requesttypes',
                view: 'request_name',
                join_rules: {
                    REQUEST_TYPE: 'request_type',
                }
            }
        },
        {
            key: 'REQUEST_DATE_TIME',
            type: Date,
            name: 'Дата и время',
        },
        {
            key: 'REQUEST_RESULT',
            type: BigInt,
            name: 'Результат инф. обмена'
        }
    ],
}
