export default {
    tableName: 'Тарифы Единого электронного билета Пересадка',
    tableCode: 'tariffse-tickettransfer',
    indexField: [
        'tariffe-tickettransfer_id'
    ],
    defaultOrder: {
        ['tariffe-tickettransfer_id']: 'ASC'
    },
    tableDescription: [
        // {
        //     key: 'tariffe-tickettransfer_id',
        //     type: BigInt,
        //     name: 'Идентификатор',
        //     width: 150,
        // },
        {
            type: BigInt,
            key: 'category_id',
            name: 'Категория ПБ',
            relation: {
                table: 'cardcategories',
                view: 'name',
                join_rules: {
                    category_id: 'category_id',
                }
            }
        },
        {
            key: 'name',
            type: String,
            name: 'Наименование',
        },
        {
            key: 'date_begin',
            type: Date,
            name: 'Начало действия'
        },
        {
            key: 'date_end',
            type: Date,
            name: 'Окончание действия'
        },
        {
            key: 'transfer_interval',
            type: BigInt,
            small_label: true,
            filter_hidden: true,
            column_hidden: true,
            name: 'Длительность интервала пересадки',
        },
        {
            key: 'tariff_transfer_1',
            type: BigInt,
            small_label: true,
            filter_hidden: true,
            column_hidden: true,
            name: 'Тариф первой поездки в течении пересадки',
        },
        {
            key: 'tariff_transfer_2',
            type: BigInt,
            small_label: true,
            filter_hidden: true,
            column_hidden: true,
            name: 'Тариф второй поездки в течении пересадки',
        },
        {
            key: 'tariff_transfer_3',
            type: BigInt,
            small_label: true,
            filter_hidden: true,
            column_hidden: true,
            name: 'Тариф третьей и следующих поездок в течении пересадки',
        },
        {
            key: 'note',
            type: String,
            name: 'Примечание',
        }
    ]
}
