import {sort} from "@/constants";

export default {
    tableName: 'Заказчики перевозок',
    tableCode: 'transportdata',
    indexField: ['ts_board_num', 'id_carrier'],
    defaultOrder: [
        {
            key: 'ts_board_num',
            type: sort.ASC,
        },
        {
            key: 'id_carrier',
            type: sort.ASC,
        },
    ],
    tableDescription: [
        {
            type: BigInt,
            name: 'Бортовой номер',
            key: 'ts_board_num',
            width: 160,
        },
        {
            type: String,
            name: 'Гос. Рег. Номер',
            key: 'transport_reg_num',
            width: 150,
        },
        {
            type: BigInt,
            key: 'id_carrier',
            name: 'Перевозчик',
            relation: {
                table: 'carriersdata',
                view: 'carrier_name',
                join_rules: {
                    id_carrier: 'id',
                },
            },
        },
        {
            type: BigInt,
            key: 'id_carrier_office',
            name: 'Филиал',
            relation: {
                table: 'carrieroffices',
                view: 'name',
                filter_by: 'id_carrier',
                join_rules: {
                    id_carrier: 'id_carrier',
                    id_carrier_office: 'id_carrier_office',
                }
            }
        },
        {
            type: BigInt,
            key: 'id_transport_type',
            name: 'Тип транспорта',
            relation: {
                table: 'transporttypes',
                view: 'description',
                join_rules: {
                    id_transport_type: 'id',
                },
            },
        },
    ]
};
