export default {
    tableCode: 'tariffse-ticket',
    tableName: 'Тарифы единого электронного билета',
    indexField: [
        'tariffe-ticket_id',
    ],
    defaultOrder: {
        ['tariffe-ticket_id']: 'ASC',
    },
    tableDescription: [
        // {
        //     key: 'tariffe-ticket_id',
        //     type: BigInt,
        //     name: 'Идентификатор',
        //     width: 150,
        // },
        {
            type: BigInt,
            key: 'category_id',
            name: 'Категория ПБ',
            relation: {
                table: 'cardcategories',
                view: 'name',
                join_rules: {
                    category_id: 'category_id',
                }
            }
        },
        {
            key: 'name',
            type: String,
            name: 'Наименование',
        },
        {
            key: 'date_begin',
            type: Date,
            name: 'Начало действия'
        },
        {
            key: 'date_end',
            type: Date,
            name: 'Окончание действия'
        },
        {
            key: 'count_trips_1',
            type: BigInt,
            filter_hidden: true,
            column_hidden: true,
            small_label: true,
            name: 'Кол-во поездок 1-го тарифного интервала'
        },
        {
            key: 'tariff_1',
            type: BigInt,
            filter_hidden: true,
            column_hidden: true,
            small_label: true,
            name: 'Тариф 1-го тарифного интервала',
        },

        {
            key: 'count_trips_2',
            type: BigInt,
            filter_hidden: true,
            column_hidden: true,
            small_label: true,
            name: 'Кол-во поездок 2-го тарифного интервала'
        },
        {
            key: 'tariff_2',
            type: BigInt,
            filter_hidden: true,
            column_hidden: true,
            small_label: true,
            name: 'Тариф 2-го тарифного интервала',
        },

        {
            key: 'count_trips_3',
            type: BigInt,
            filter_hidden: true,
            column_hidden: true,
            small_label: true,
            name: 'Кол-во поездок 3-го тарифного интервала'
        },
        {
            key: 'tariff_3',
            type: BigInt,
            filter_hidden: true,
            column_hidden: true,
            small_label: true,
            name: 'Тариф 3-го тарифного интервала',
        },

        {
            key: 'count_trips_4',
            type: BigInt,
            filter_hidden: true,
            column_hidden: true,
            small_label: true,
            name: 'Кол-во поездок 4-го тарифного интервала'
        },
        {
            key: 'tariff_4',
            type: BigInt,
            filter_hidden: true,
            column_hidden: true,
            small_label: true,
            name: 'Тариф 4-го тарифного интервала',
        },

        {
            key: 'count_trips_5',
            type: BigInt,
            filter_hidden: true,
            column_hidden: true,
            small_label: true,
            name: 'Кол-во поездок 5-го тарифного интервала'
        },
        {
            key: 'tariff_5',
            type: BigInt,
            filter_hidden: true,
            column_hidden: true,
            small_label: true,
            name: 'Тариф 5-го тарифного интервала',
        },

        {
            key: 'count_trips_6',
            type: BigInt,
            filter_hidden: true,
            column_hidden: true,
            small_label: true,
            name: 'Кол-во поездок 6-го тарифного интервала'
        },
        {
            key: 'tariff_6',
            type: BigInt,
            filter_hidden: true,
            column_hidden: true,
            small_label: true,
            name: 'Тариф 6-го тарифного интервала',
        },

        {
            key: 'count_trips_7',
            type: BigInt,
            filter_hidden: true,
            column_hidden: true,
            small_label: true,
            name: 'Кол-во поездок 7-го тарифного интервала'
        },
        {
            key: 'tariff_7',
            type: BigInt,
            filter_hidden: true,
            column_hidden: true,
            small_label: true,
            name: 'Тариф 7-го тарифного интервала',
        },

        {
            key: 'count_trips_8',
            type: BigInt,
            filter_hidden: true,
            column_hidden: true,
            small_label: true,
            name: 'Кол-во поездок 8-го тарифного интервала'
        },
        {
            key: 'tariff_8',
            type: BigInt,
            filter_hidden: true,
            column_hidden: true,
            small_label: true,
            name: 'Тариф 8-го тарифного интервала',
        },

        {
            key: 'count_trips_9',
            type: BigInt,
            filter_hidden: true,
            column_hidden: true,
            small_label: true,
            name: 'Кол-во поездок 9-го тарифного интервала'
        },
        {
            key: 'tariff_9',
            type: BigInt,
            filter_hidden: true,
            column_hidden: true,
            small_label: true,
            name: 'Тариф 9-го тарифного интервала',
        },

        {
            key: 'count_trips_10',
            type: BigInt,
            filter_hidden: true,
            column_hidden: true,
            small_label: true,
            name: 'Кол-во поездок 10-го тарифного интервала'
        },
        {
            key: 'tariff_10',
            type: BigInt,
            filter_hidden: true,
            column_hidden: true,
            small_label: true,
            name: 'Тариф 10-го тарифного интервала',
        },
        {
            key: 'note',
            type: String,
            name: 'Примечание',
        }
    ]
}
