export default {
    tableName: 'Типы маршрутов',
    tableCode: 'routetypes',
    indexField: ['route_type'],
    defaultOrder: {
        route_type: 'ASC',
    },
    viewField: 'description',
    tableDescription: [
        {
            type: BigInt,
            name: 'Идентификатор',
            key: 'route_type',
            width: 150,
        },
        {
            type: String,
            name: 'Наименование',
            key: 'description',
        },
    ]
};
