export default {
    tableName: 'Правила приема ПБ',
    tableCode: 'acceptrulesdict',
    indexField: [
        'acceptrule_id'
    ],
    defaultOrder: {
        acceptrule_id: 'ASC',
    },
    viewField: 'name',
    tableDescription: [
        // {
        //     key: 'acceptrule_id',
        //     name: 'Идентификатор',
        //     type: BigInt,
        // },
        {
            key: 'name',
            name: 'Наименование',
            type: String,
        },
    ]
}
