import {sort} from "@/constants";

export default {
    tableName: 'Черный список',
    tableCode: 'blacklist',
    indexField: ['card_number', 'card_type'],
    defaultOrder: [
        {
            key: 'priority',
            type: sort.DESC,
        },
        {
            key: 'block_date_time',
            type: sort.DESC,
        },
        {
            key: 'card_number',
            type: sort.ASC,
        }
    ],
    tableDescription: [
        {
            type: BigInt,
            name: 'Номер ПБ',
            key: 'card_number',
        },
        {
            type: String,
            name: 'Тип ПБ',
            key: 'card_type',
            relation: {
                table: 'cardsdataunique',
                join_rules: {
                    card_type: 'card_type',
                },
                view: 'card_description',
            },
        },
        {
            key: 'block_date_time',
            name: 'Дата внесения',
            type: Date,
            default_value: () => Date.now(),
        },
        {
            key: 'unblock_date_time',
            name: 'Дата исключения',
            type: Date,
        },
        {
            key: 'reason',
            name: 'Причина блокировки',
            type: BigInt,
            relation: {
                table: 'reasonsdata',
                view: 'description',
                join_rules: {
                    reason: 'id_reasons',
                },
            },
        },
        {
            key: 'priority',
            name: 'Приоритет',
            type: BigInt,
            relation: {
                table: 'prioritydata',
                view: 'description',
                join_rules: {
                    priority: 'id_priority',
                },
            },
        }
    ]
};
