<template lang="pug">
  el-drawer(
    :title="mode === 'edit' ? 'Редактирование ряда' : 'Добавление ряда'"
    :visible="opened"
    v-on:update:visible="v => handleModalOpen(v)"
    direction="rtl"
    :before-close="beforeClose"
    custom-class="demo-drawer"
    ref="drawer"
    v-on:open="modalOpened"
  )

    .demo-drawer__content
      el-form
        template(v-for="item in structure")
          template(v-if="item.relation")
            el-form-item(:label="item.name" :class="{ small_label: item.small_label }")
              el-select(
                :value="selectValue(item.key, item)"
                v-on:input="value => updateSelect(item.key, value, item)"
                filterable
                collapse-tags
                clearable
                :disabled='checkDisable(item)'
                :multiple="item.multiple"
                :placeholder="item.name"
              )
                el-option(
                  v-for="item in selectOptions(item.relation.table, item.relation.filter_by, item.relation.depends_on, item)"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                )
          template(v-else-if="item.type === Boolean")
            el-form-item(:label="item.name" :class="{ small_label: item.small_label }")
              el-select.input__column(
                :value='booleanValue(item.key)'
                v-on:input="value => targetData[item.key] = value"
                :placeholder="item.name"
                :disabled='checkDisable(item)'
              )
                el-option(key="0" label="Нет" :value="0")
                el-option(key="1" label="Да" :value="1")

          template(v-else-if="item.type === BigInt")
            el-form-item(:label="item.name" :class="{ small_label: item.small_label }")
              el-input(
                type="string"
                :placeholder="item.name"
                v-on:input="val => insertBigNumber(val, item.key)"
                :value="targetData[item.key]"
              )

          template(v-else-if="item.type === Number")
            el-form-item(:label="item.name" :class="{ small_label: item.small_label }")
              el-input(
                type="number"
                :placeholder="item.name"
                v-model="targetData[item.key]"
              )

          template(v-else-if="item.type === Date")
            el-form-item.date_input(:label="item.name" :class="{ small_label: item.small_label }")
              el-date-picker(
                :value='dateValue(item.key, item)'
                v-on:input='val => insertDate(item.key, val)'
                type="datetime"
              )

          template(v-else-if="item.type === String")
            el-form-item(:label="item.name" :class="{ small_label: item.small_label }")
              el-input(
                :placeholder="item.name"
                v-model="targetData[item.key]"
              )

        el-row.buttons__block
          el-button(v-on:click="save" type="primary") Сохранить
          el-button(type="warning" v-on:click="close" plain) Отмена

</template>

<script>
import * as R from 'ramda';
import axios from "@/utils/axios";
import {mapGetters} from "vuex";
import tables from "@/tables";
import formatDate from "@/utils/formatDate";

export default {
  name: 'ContentModal',
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      validate: (item) => ['edit', 'create'].includes(item),
    },
    structure: {
      type: Array,
    },
    values: {
      type: Object,
    },
    tableCode: {
      type: String,
      required: false,
    }
  },
  data: () => ({
    buffer: {},
    targetData: {},
    BigInt,
    allSelectActivations: {},
    defaultValueInit: {},
  }),
  computed: {
    ...mapGetters({
      getTable: 'unitable/getTable',
    }),
  },
  methods: {
    handleModalOpen(v) {
      this.$emit('update:opened', v)
    },
    close() {
      this.$refs.drawer.closeDrawer();
    },
    checkDisable(item) {
      if (!item.depends_on) return false;

      return !item.depends_on.every(key => (this.targetData[key] || []).length);
    },
    selectValue(key, item) {
      console.log(key, item.default_value, item);
      if (!this.targetData[key] && item.default_value) {
        console.log('SETTING DEFAULT');
        this.targetData[key] = item.default_value;
        return this.targetData[key];
      }

      if (typeof this.targetData[key] === 'undefined') return;

      if (!item.multiple) return this.targetData[key].toString();


      if (Array.isArray(this.targetData[key])) {

        const selectOptions = this.selectOptions(
            item.relation.table, item.relation.filter_by, item.relation.depends_on, item
        ).map(i => i.value);

        const optionsWithoutAll = selectOptions.slice(1);

        if (this.targetData[key].length && R.symmetricDifference(optionsWithoutAll, this.targetData[key]).length === 0) {
          this.targetData[key] = R.prepend(-1, this.targetData[key]);
          this.allSelectActivations[key] = true;
        }

        return this.targetData[key];
      }

      return this.targetData[key] ? [...new Set(this.targetData[key].split(','))] : [];
    },
    booleanValue(key) {
      return Number(this.targetData[key]);
    },
    dateValue(key, item) {
      if (this.targetData[key] || !item.default_value) {
        return this.targetData[key];
      }

      if (!this.defaultValueInit[key]) {
        this.defaultValueInit[key] = true;
        this.targetData[key] = item.default_value();
      }

      return this.targetData[key];
    },
    updateSelect(key, value, item) {

      const selectOptions = this.selectOptions(
          item.relation.table, item.relation.filter_by, item.relation.depends_on, item
      ).map(i => i.value);

      // const optionsWithoutAll = selectOptions.slice(1);

      if (value.includes(-1) && !this.allSelectActivations[key]) {
        this.allSelectActivations[key] = true;
        this.targetData[key] = selectOptions;
      } else if (!value.includes(-1) && this.allSelectActivations[key]) {
        this.allSelectActivations[key] = false;
        this.targetData[key] = [];
      } else if (value.includes(-1) && this.allSelectActivations[key]) {
        // all select turned on
        // console.log(R.symmetricDifference(selectOptions, value));
        if (R.symmetricDifference(selectOptions, value).length !== 0) {
          this.allSelectActivations[key] = false;
          this.targetData[key] = value.slice(1); // remove "all" active
        }
      } else {
        this.targetData[key] = value;
      }

      if (item.must_clear) {
        item.must_clear.forEach(i => this.targetData[i] = []);
        item.must_clear.forEach(i => this.allSelectActivations[i] = false);
      }

      this.targetData = {...this.targetData};
    },
    modalOpened() {
      this.buffer = {...this.values};
      this.buffer.source = this.buffer.source ? {...this.buffer.source} : {};
      this.targetData = this.buffer.source ? {...this.buffer.source} : {};
      Object.keys(this.targetData).forEach(key => {
        const structureItem = this.getItem(key);

        if (!structureItem) return;

        if (structureItem.type === Date) {
          this.targetData[key] = formatDate(this.targetData[key]);
        }

        if (structureItem.decorator_in) {
          this.targetData[key] = structureItem.decorator_in(this.targetData[key]);
        }

        this.targetData[key] = typeof this.targetData[key] === 'string' && structureItem.multiple ?
            this.targetData[key].split(',') : this.targetData[key];
      })
      this.targetData = {...this.targetData};
      this.defaultValueInit = {};
      this.resetDateAttributes();
    },
    resetDateAttributes() {
      setTimeout(() => {
        const dateInputs = document.querySelectorAll('.date_input');

        dateInputs.forEach(item => {
          const Input = item.querySelector('input.el-input__inner');
          Input.setAttribute('readonly', 'readonly');
        });

      }, 1e1);
    },
    getItem(key) {
      return this.structure.find(item => item.key === key);
    },
    selectOptions(table, filter_by, depends_on, item) {
      try {
        const currentTable = tables[table]();

        const middlewares = [];

        middlewares.push(this.getTable);

        if (filter_by) {
          middlewares.push(R.filter(item => item[filter_by] === this.targetData[filter_by]))
        }

        if (depends_on) {
          Object.entries(depends_on)
              .filter(([localKey]) => this.targetData[localKey])
              .filter(([localKey]) => this.targetData[localKey].length)
              .forEach(([localKey, sourceKey]) => {
                    middlewares.push(R.filter(item => this.targetData[localKey].includes(item[sourceKey].toString())))
                  }
              )
        }

        if (item.relation.limit_by) {
          Object.keys(item.relation.limit_by).forEach(localTable => {
            const limitRules = item.relation.limit_by[localTable];
            const limitTable = this.getTable(localTable);

            const localMiddlewares = [];

            localMiddlewares.push(R.filter(item => {
              // if (table === 'carriersdata') console.log(JSON.stringify(limitRules.fields));
              const result = Object.entries(limitRules.fields).every(([local, target]) => {

                    // if (table === 'carriersdata') console.log(local);
                    return (this.targetData[local] || []).includes(item[target].toString())

                    // return result;
                  }
              )

              if (result && table === 'carriersdata') {
                // console.log(item, this.targetData);
              }

              return result;

            }));


            localMiddlewares.push(R.uniqBy(R.prop(limitRules.target)));

            localMiddlewares.push(R.map(R.prop(limitRules.target)))

            const data = R.pipe(...localMiddlewares)(limitTable)

            middlewares.push(R.filter(item => {
              return data.includes(item[limitRules.compare || limitRules.target])
            }));
          })
        }

        const indexes = filter_by ?
            currentTable.indexField.filter(item => item !== filter_by) :
            [...currentTable.indexField];


        middlewares.push(R.map(item => ({
          name: item[currentTable.viewField],
          value: indexes.length > 1 ? indexes.map(i => `${i}:${item[i]}`).join('/') : item[indexes[0]].toString(),
        })));

        if (item.multiple) {
          middlewares.push((array) => {
            if (array.length) {
              return R.prepend({name: 'Все', value: -1})(array)
            }
            return [];
          })
        }

        middlewares.push(R.uniqBy(R.prop('value')))

        return R.pipe(...middlewares)(table);

      } catch (e) {
        console.error(e);
      }
    },
    async save() {
      const currentTable = tables[this.tableCode]();

      const index = currentTable.indexField;

      const payload = JSON.parse(JSON.stringify(this.targetData));

      //console.log(index, payload, this.buffer.source);
      const primaryKey = this.buffer.source ? R.pick(index, this.buffer.source) : R.pick(index, payload);

      Object.keys(payload).forEach(key => {
        const columnInfo = this.getItem(key);
        if (Array.isArray(payload[key]) && payload[key].includes(-1)) {
          payload[key] = payload[key].slice(1);
        }

        if (columnInfo.decorator_out) {
          payload[key] = columnInfo.decorator_out(payload[key]);
        }

        if (columnInfo && columnInfo.type === Date) {
          payload[key] = new Date(payload[key]);
        }
        if (columnInfo && columnInfo.type === Boolean) {
          payload[key] = Boolean(payload[key]);
        }
      })

      try {
        if (this.mode === 'edit') {
          await this.$store.dispatch('unitable/editItem', {
            table_name: this.tableCode,
            primary_key: primaryKey,
            payload,
          });

        } else if (this.mode === 'add') {
          await this.$store.dispatch('unitable/addItem', {
            table_name: this.tableCode,
            payload,
          });
        }
        //console.log(primaryKey, index, this.buffer.source, payload);
        if (['blacklistflc_ext', 'blacklistflc_reg'].includes(this.tableCode)) {
          await axios
              .post(`/${this.tableCode}/revalidate`, { primary_key: primaryKey });
        }
      } catch (e) {
        console.error(e);
        this.$notify.error({
          title: 'Ошибка',
          message: e.response.data.error
        });
        return;
      }
      this.$emit('update-success');
      this.close();

    },
    insertDate(key, val) {
      this.targetData[key] = val;
      this.targetData = {...this.targetData};
    },
    insertBigNumber(val, key) {
      this.targetData[key] = val
          .toString()
          .split('')
          .map(Number)
          .filter(i => !Number.isNaN(i))
          .join('');
      this.targetData = {...this.targetData};
    },
    beforeClose(done) {
      done();
    }
  },
}
</script>

<style lang="stylus">

.el-input
  width auto !important

.el-form-item__label
  line-height auto !important
  width 200px

.buttons__block
  margin-left 20px
  text-align center

.small_label .el-form-item__label
  line-height 20px !important

.el-date-picker__time-header .el-date-picker__editor-wrap:first-child
  display none !important
</style>
