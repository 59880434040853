export default {
    tableName: 'Таблица приоритетов',
    tableCode: 'prioritydata',
    indexField: ['id_priority'],
    viewField: 'description',
    defaultSort: {
      id_priority: 'ASC',
    },
    tableDescription: [
        {
            type: BigInt,
            name: 'Идентификатор',
            key: 'id_priority',
        },
        {
            type: String,
            name: 'Описание',
            key: 'description',
        }
    ]
}
