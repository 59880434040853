import {sort} from "@/constants";

export default {
    tableName: 'Справочник типов проездных билетов',
    tableCode: 'cardsdata',
    indexField: ['cardsdata_id'],
    viewField: 'card_description',
    defaultOrder: [
        {
            key: 'card_type',
            type: sort.ASC,
        },
        {
            key: 'card_form',
            type: sort.ASC,
        },
    ],
    tableDescription: [
        // {
        //   type: BigInt,
        //   name: 'ID',
        //   key: 'cardsdata_id',
        // },
        {
            type: BigInt,
            name: 'Тип билета',
            key: 'card_type',
            width: 120,
        },
        {
            type: BigInt,
            name: 'Вид билета',
            key: 'card_form',
            width: 120,

        },
        {
            type: String,
            name: 'Наименование',
            key: 'card_description',
        },
        {
            type: BigInt,
            name: 'ID категории',
            key: 'category_id',
            relation: {
                table: 'cardcategories',
                join_rules: {
                    category_id: 'category_id',
                },
                view: 'name',
            }
        },
        // {
        //     type: Date,
        //     key: 'activate_date',
        //     name: 'Дата активации',
        // },
        // {
        //     type: Date,
        //     key: 'block_date',
        //     name: 'Дата блокировки',
        // },
        {
            type: String,
            key: 'card_description_short',
            name: 'Короткое описание карты',
        }
    ]
}
