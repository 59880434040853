import * as R from 'ramda';
import Blacklist from "@/tables/Blacklist";
import ReasonsData from "@/tables/ReasonsData";
import BoTypes from "@/tables/BoTypes";
import CardsData from "@/tables/CardsData";
import PriorityData from "@/tables/PriorityData";
import CarrierOffices from "@/tables/CarrierOffices";
import CarriersData from "@/tables/CarriersData";
import MetroStations from "@/tables/MetroStations";
import RoutesData from "@/tables/RoutesData";
import RouteTypes from "@/tables/RouteTypes";
import Tariffs from "@/tables/Tariffs";
import TransportCustomers from "@/tables/TransportCustomers";
import TransportData from "@/tables/TransportData";
import TransportTypes from "@/tables/TransportTypes";
import ZoneTariffs from "@/tables/ZoneTariffs";
import ZoneTariffsDetails from "@/tables/ZoneTariffsDetails";
import History from "@/tables/History";
import CardsDataUnique from "@/tables/CardsDataUnique";
import BlacklistDatBuffer from "@/tables/BlacklistDatBuffer";
import SourceSystems from "@/tables/SourceSystems";
import Request_History from "@/tables/RequestHistory";
import RequestTypes from "@/tables/RequestTypes";
import sortTransform from "@/utils/sortTransform";
import CardsCategories from "@/tables/CardsCategories";
import AcceptRules from "@/tables/AcceptRules";
import AcceptRulesDict from "@/tables/AcceptRulesDict";
import TariffsTicket from "@/tables/TariffsTicket";
import TariffTicketTransfer from "@/tables/TariffTicketTransfer";
import ExtSales from "@/tables/ExtSales";
import ExtSalesTemplates from "@/tables/ExtSales/ExtSalesTemplates";
import ProtocolBocotExt from "@/tables/ProtocolBocotExt";
import Signatory from "@/tables/Signatory";
import JobTitle from "@/tables/JobTitle";
import BlackListFLC_Ext from "@/tables/Blacklist/BlackListFLC_Ext";
import BlackListFLC_Reg from "@/tables/Blacklist/BlackListFLC_Reg";
import PaymentSystems from "@/tables/PaymentSystems";
import tarrifs_bankcards from "@/tables/tariffs_bankcards";
import securitymodules from "@/tables/securitymodules";

const sortDecorator = table => {

    if (!table.defaultOrder) return table;

    if (Array.isArray(table.defaultOrder)) return table;

    const defaultOrder = sortTransform(table.defaultOrder);

    return {
        ...table,
        defaultOrder,
    }
}

const tableDecorator = R.pipe(
  sortDecorator,
);

const tables = {
    acceptrules: () => tableDecorator(AcceptRules),
    acceptrulesdict: () => tableDecorator(AcceptRulesDict),
    blacklist: () => tableDecorator(Blacklist),
    blacklistflc_ext: () => tableDecorator(BlackListFLC_Ext),
    blacklistflc_reg: () => tableDecorator(BlackListFLC_Reg),
    blacklist_dat_buffer: () => tableDecorator(BlacklistDatBuffer),
    reasonsdata: () => tableDecorator(ReasonsData),
    bo_types: () => tableDecorator(BoTypes),
    cardsdata: () => tableDecorator(CardsData),
    cardcategories: () => tableDecorator(CardsCategories),
    cardsdataunique: () => tableDecorator(CardsDataUnique),
    prioritydata: () => tableDecorator(PriorityData),
    carrieroffices: () => tableDecorator(CarrierOffices),
    carriersdata: () => tableDecorator(CarriersData),
    sourcesystems: () => tableDecorator(SourceSystems),
    request_history: () => tableDecorator(Request_History),
    ['tariffse-ticket']: () => tableDecorator(TariffsTicket),
    ['tariffse-tickettransfer']: () => tableDecorator(TariffTicketTransfer),
    history: () => tableDecorator(History),
    paymentsystems: () => tableDecorator(PaymentSystems),
    tariffs_bankcards: () => tableDecorator(tarrifs_bankcards),
    securitymodules: () => tableDecorator(securitymodules),
    metrostations: () => tableDecorator(MetroStations),
    protocol_bocot_ext: () => tableDecorator(ProtocolBocotExt),
    routesdata: () => tableDecorator(RoutesData),
    requesttypes: () => tableDecorator(RequestTypes),
    routetypes: () => tableDecorator(RouteTypes),
    tariffs: () => tableDecorator(Tariffs),
    signatory: () => tableDecorator(Signatory),
    job_title: () => tableDecorator(JobTitle),
    transportcustomers: () => tableDecorator(TransportCustomers),
    transportdata: () => tableDecorator(TransportData),
    transporttypes: () => tableDecorator(TransportTypes),
    zonetariffs: () => tableDecorator(ZoneTariffs),
    zonetariffsdetails: () => tableDecorator(ZoneTariffsDetails),
    cardscategories: () => tableDecorator(CardsCategories),
    extsales: () => tableDecorator(ExtSales),
    extsalestemplates: () => tableDecorator(ExtSalesTemplates),
}

export default tables;
