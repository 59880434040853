<template lang="pug">
  el-aside(:class="{ scroll__behavior: true, width__auto: menuHidden }")
    //el-radio-group(v-model="isCollapse" style="margin-bottom: 20px;")
      el-radio-button(:label="false") expand
      el-radio-button(:label="true") collapse
    //h5.name АРМ Оператора
    el-menu.el-menu-vertical-demo(
      :default-active="currentActive"
      unique-opened
      :collapse="menuHidden"
    )


      template(v-for="(item, index) in menuAfterGrants")
        el-submenu(:index="index.toString()" v-if="item.sub_menus && item.sub_menus.length"
          :class="{double_line: item.isDoubleLine}"
          :key="item.key"
          )
          //router-link(:to="item.key" slot="title" tag="div")
          div(slot="title")
            arm-icon(:name="item.icon")
            span(v-html="item.name")

          el-menu-item-group
            router-link(
              tag="div"
              v-for="(subItem, subIndex) in item.sub_menus"
              :to="subItem.key"
              :key="subItem.key"
            ): el-menu-item(:index="`${index}-${subIndex}`"
                :active="subItem.active"
                style='padding-left: 40px !important'
                :class="{double_line: subItem.isDoubleLine}"
                v-html="subItem.name"
                icon
              )

        router-link(
          tag="div"
          :to="item.key"
          v-else :key="item.key"
        ): el-menu-item(:index="index.toString()")
          arm-icon(:name="item.icon" )
          span(slot="title") {{item.name}}

      el-menu-item(v-on:click="logout" index="100000")
        arm-icon(name='close')
        span(slot="title") Выход

</template>

<script>
import ArmIcon from "@/components/ArmIcon";
import {ROUTE_CONSTANTS} from "@/router";
import { mapState, mapGetters } from "vuex";
import {USER_ROLES} from "@/constants";

export default {
  name: 'ArmNavigation',
  components: {ArmIcon},
  data: () => ({
    menu: [
      {
        name: 'Управление НСИ',
        key: ROUTE_CONSTANTS.NSI_MANAGEMENT,
        icon: 's-management',
        sub_menus: [
          {
            name: 'Проездные билеты',
            key: ROUTE_CONSTANTS.NSI_CARDSDATA,
          },
          {
            name: 'Перевозчики',
            key: ROUTE_CONSTANTS.NSI_CARRIERSDATA,
          },
          {
            name: 'Филиалы перевозчиков',
            key: ROUTE_CONSTANTS.NSI_CARRIERSOFFICES,
          },
          {
            name: 'Типы транспорта',
            key: ROUTE_CONSTANTS.NSI_TRANSPORT_TYPES,
          },
          {
            name: 'Транспортные средства',
            key: ROUTE_CONSTANTS.NSI_TRANSPORT_DATA,
          },
          {
            name: 'Типы маршрутов',
            key: ROUTE_CONSTANTS.NSI_ROUTESTYPES,
          },
          {
            name: 'Тарифы по банковским картам',
            key: ROUTE_CONSTANTS.NSI_TARIFFS_BANKCARDS,
          },
          {
            name: 'Модули безопасности',
            key: ROUTE_CONSTANTS.NSI_SECURITY_MODULES,
          },
          {
            name: 'Заказчики перевозок',
            key: ROUTE_CONSTANTS.NSI_TRANSPORT_CUSTOMER,
          },
          {
            name: 'Станции метро',
            key: ROUTE_CONSTANTS.NSI_METROSTATIONS,
          },
          {
            name: 'Маршруты',
            key: ROUTE_CONSTANTS.NSI_ROUTESDATA,
          },
          {
            name: 'История изменения НСИ',
            key: ROUTE_CONSTANTS.NSI_HISTORY,
            active: true,
          },
          {
            name: 'Справочник результатов<br> информационного обмена с БО',
            key: ROUTE_CONSTANTS.NSI_REQUEST_HISTORY,
            isDoubleLine: true,
          },
          {
            name: 'Справочник модулей ЦОТ',
            key: ROUTE_CONSTANTS.NSI_SOURCE_SYSTEMS,
          },
          {
            name: 'Справочник типов запросов',
            key: ROUTE_CONSTANTS.NSI_REQUEST_TYPES,
          },
          {
            name: 'Прочие продажи',
            key: ROUTE_CONSTANTS.NSI_EXT_SALES
          },
          {
            name: 'Параметры протокола БО ЦОТ',
            key: ROUTE_CONSTANTS.PROTOCOL_BOCOT_EXT,
          },
          {
            name: 'Уполномоченные лица',
            key: ROUTE_CONSTANTS.SIGNATORY,
          },
          {
            name: 'Список должностей',
            key: ROUTE_CONSTANTS.JOB_TITLE,
          },
        //////
          /*{
            name: 'Тарифы',
            key: ROUTE_CONSTANTS.NSI_TARIFFS,
          },
          {
            name: 'Типы БО',
            key: ROUTE_CONSTANTS.NSI_BOTYPES,
          },
          {
            name: 'Перечень зональных <br>тарифных политик',
            key: ROUTE_CONSTANTS.NSI_ZONE_TARIFFS,
            isDoubleLine: true,
          },
          {
            name: 'Зональная тарифная политика',
            key: ROUTE_CONSTANTS.NSI_ZONE_TARIFFS_DETAILS,
          },*/
        ]
      },
      {
        name: 'Управление ЧС',
        key: ROUTE_CONSTANTS.NSI_BLACKLIST,
        icon: 's-release',
        sub_menus: [
          {
            name: 'Черный Список',
            key: ROUTE_CONSTANTS.NSI_BLACKLIST_LIST,
            // icon: 's-order',
          },
          {
            name: 'Причины блокировки',
            key: ROUTE_CONSTANTS.NSI_BLACKLIST_REASONS,
            // icon: 'question',
          }
        ],
      },
      {
        name: 'Управление отчетами ЦОТ',
        key: ROUTE_CONSTANTS.COT_REPORTS_MANAGEMENT,
        icon: 's-data',
        sub_menus: [],
      },
      {
        name: 'Управление действующими <br>правилами приема ПБ',
        key: '/pb',
        isDoubleLine: true,
        icon: 's-ticket',
        sub_menus: [
          {
            name: 'Категории (правила гашения) ПБ',
            key: ROUTE_CONSTANTS.PB_CARDCATEGORIES,
          },
          {
            name: 'Действующие правила приема ПБ',
            key: ROUTE_CONSTANTS.PB_ACCEPT_RULES,
          },
         {
            name: 'Правила приема ПБ',
            key: ROUTE_CONSTANTS.PB_ACCEPT_RULES_DICT,
          },
          {
            name: 'Тарифы Единого электронного <br>билета',
            key: ROUTE_CONSTANTS.PB_TARIFFSE_TICKET,
            isDoubleLine: true,
          },
          {
            name: 'Тарифы Единого электронного <br>билета Пересадка',
            key: ROUTE_CONSTANTS.PB_TARIFFSE_TICKET_TRANSFER,
            isDoubleLine: true,
          },
        ]
      },
      {
        name: 'Администрирование',
        key: ROUTE_CONSTANTS.ADMINISTRATION,
        icon: 's-custom',
        sub_menus: [
          {
            name: 'Пользователи',
            key: ROUTE_CONSTANTS.ADMINISTRATION_USERS
          }
        ],
      },
    ],
  }),
  computed: {
    menuAfterGrants() {
      if (this.role === USER_ROLES.nsi_operator) {
        return [this.menu[0]];
      }

      if (this.role === USER_ROLES.blacklist_operator) {
        return [this.menu[1]]
      }

      if (this.role === USER_ROLES.reports_operator) {
        return [this.menu[2]];
      }

      return this.menu;
    },
    ...mapGetters(['role']),
    ...mapState(['menuHidden']),
    currentActive() {
      for (let i = 0; i < this.menu.length; i++) {
        const item = this.menu[i];
        if (item.key === this.$route.path) {
          return i.toString();
        }
        if (item.sub_menus && item.sub_menus.length) {
          for (let j = 0; j < item.sub_menus.length; j++) {
            const subItem = item.sub_menus[j];

            if (subItem.key === this.$route.path) {
              return `${i}-${j}`;
            }
          }
        }
      }
      return '0';
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
    }
  }
}
</script>

<style lang="stylus" scoped>

.width__auto
  width auto !important

.name
  font-family 'Lobster', cursive
  padding-left 30px
  font-size 1.5em
  margin 20px auto

.scroll__behavior
  height 100vh
  overflow-y scroll

//.el-menu-vertical-demo:not(.el-menu--collapse)
//  width: 200px;
//  min-height: 400px;

.double_line
  line-height 20px !important

.el-submenu.double_line
  line-height 28px !important

</style>

<style lang="stylus">
.el-menu-item-group__title
  display none

li.el-menu-item
  line-height 40px !important
  height 40px !important

.el-menu--collapse
  .el-submenu.double_line
    .el-submenu__title
      line-height 56px !important

.el-submenu.double_line
  .el-submenu__title
    line-height 28px !important
  span
    display inline-block

</style>
